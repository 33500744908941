<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>

    <a class="logo" href="/controller"><img style="width: 100px;" src="./assets/images/logo_bbmnet.png" /></a>
    <a class="logo" href="/controller"><img style="width: 60px;" src="./assets/images/logo-bolsa.png" /></a>

    <form [formGroup]="form">
      <nb-form-field>
          <nb-select  placeholder="Participante" fullWidth="true" size="medium" formControlName="participanteId" (selectedChange)="getUserRoles($event)">
            <nb-option nbTooltip="Com esta visão de sociedade você poderá visualizar todas licitações porém sem poder participar." value="sociedade">Sociedade - acompanhar licitações</nb-option>
            <nb-option *ngFor="let s of this.listParticipantes" [nbTooltip]="s.participante.nome +' '+ '(' + getDocumentoToolTip(s.participante.documento) + ')'" [value]="s.participante.uniqueId">{{s.participante.nome.toString().length>25?s.participante.nome.toString().substring(0,25) + "...":s.participante.nome}}</nb-option>
          </nb-select>
      </nb-form-field>
    </form>

    <div>
      <app-user-set-sociedade [listParticipantes]="listParticipantes" [isSociedade]="isSociedade"></app-user-set-sociedade>
    </div>
  </div>
</div>

<app-user-menu [user]="user" [nomeUser]="user?.name" [perfilUser]="user?.profile"></app-user-menu>


