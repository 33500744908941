<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="./assets/images/logo_bbmnet.png" /></a>
    <a class="logo" href="#" (click)="navigateHome()"><img style="width: 100px;" src="./assets/images/logo-bolsa.png" /></a>
    
    <div class="name"><b>Sala de Disputa</b> / Pregão</div>
  </div>
  

</div>

<div class="header-container">
  <div class="logo">
    <nb-icon icon="message-square-outline"></nb-icon>
  </div>
  <div class="logo">
    <nb-icon icon="edit-2-outline"></nb-icon>
  </div>
  <div class="logo">
    <nb-icon icon="flag-outline"></nb-icon>
  </div>
  <nb-actions size="small">
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
