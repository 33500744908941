import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { NbToastrService } from '@nebular/theme';
import { UsuarioService } from '../../services/usuario.service';
import { LoadingService } from '../../services/loading.service';
import { UtilsService } from '../../services/utils.service';
import { ParticipanteDados, UsuarioDoParticipante } from '../../models/participante';
import { PARTICIPANTE_STATUS, PARTICIPANTE_USUARIO_STATUS } from '../../enums/participante';
import { SISTEMAS } from '../../enums/sistemas';
import { InsertValidateCredencials } from '../../services/insert-validate-credencials';
import { environment } from '../../../../environments/environment';
import { ParticipanteAtivo } from '../../utils/filterParticipanteAtivo';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CriptoLocalStorageService } from '../../services/cripto-localstorage/cripto-localstorage.service';



@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

  usuarios : any 
  cardAtivo : boolean = false
  public tipoParticipante :any;
  public autorizacao :any
  hideElement : boolean = true
  public tooltip:string ='';
  public participantes:any = [];
  public env = environment;
  partAtivo : boolean = false
  idUsuario: any;
  public isTherePessoaFisica:boolean = false;
  public tipoParticipanteNome: any;
  public participanteAtivo = new ParticipanteAtivo();
  public form!: FormGroup;


  constructor(
    private usuario: UsuarioService,
    private cookieService : CriptoLocalStorageService,
    private keycloak: KeycloakService,
    private loadingService: LoadingService,
    private router: Router,
    private utils: UtilsService,
    private toast: NbToastrService,
    private formBuilder: FormBuilder,
    private insertValidateCredencial: InsertValidateCredencials,
  ) { 

    ;

  }

  ngOnInit(): void {
    this.idUsuario = this.keycloak.getKeycloakInstance().idTokenParsed;   
    this.getparticipanteUsuario(this.idUsuario.sub)
    this.createForm()
  }

  private createForm() {
    this.form = this.formBuilder.group({
      usuario: [undefined,[Validators.required]],
    })
  }

  get f() {
    return this.form.controls;
  }

  getparticipanteUsuario(idUsuario:string){
    this.loadingService.show();
    this.usuario.getParticipantesUsuario(idUsuario).subscribe((data)=>{
      let participantesList = data
      this.autorizacao = participantesList.autorizacoes
      this.usuarios = this.participanteAtivo.retornaparticipanteAtivoVerificacao(participantesList);
      this.loadingService.hide();  
    })
  }

  goToPage(participante?: any){
    if(this.f.usuario.valid){
      this.getUserRoles(this.f.usuario.value);
    }
  }


  getUserRoles(autorizacao){
    var userRoles:string[] = this.insertValidateCredencial.getUserRoles(autorizacao.usuario.autorizacoes,autorizacao.participante.uniqueId);
    // if(userRoles.includes('orgaopromotor') || userRoles.includes('unidadecompradora')) {
    //     this.loadingService.hide();
    //     this.router.navigateByUrl('/controller')
    // }else if(userRoles.includes('licitante')) {
    //   this.loadingService.hide();
    //   this.router.navigateByUrl('/controller');
    // }else if(userRoles.includes('Bbm')) {
    //   this.loadingService.hide();
    //   this.router.navigateByUrl('/controller');
    // }

    if(userRoles.includes('corretora')) {
      this.loadingService.hide();
      this.router.navigateByUrl('/visaoeditais');
    }else {
      this.loadingService.hide();
      this.router.navigateByUrl('/controller');
    }
  }

  logout() {
    this.cookieService.deleteAll("/");
    localStorage.removeItem("auth_app_token");
    this.keycloak.logout(this.env.frontRef + '/auth/login');
  }

}