import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filterSegmentos'
})
export class FilterSegmentosPipe implements PipeTransform {
    transform(items: any[], id: number): any {
        if (!items || !id) {
            return  null;
        }

        const item:any = items.filter(item => item.uniqueId === id);
 
        return item[0]?.segmentos;
    }
}