import { Component, OnDestroy, OnInit } from "@angular/core";
import { MenuItensGeral } from "../../pages-menu";
import { StatusService } from "../../shared/services/status.service";
import { ChangePath } from "../../shared/services/redirect.service";
import { CriptoLocalStorageService } from "../../shared/services/cripto-localstorage/cripto-localstorage.service";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  changePath: ChangePath;
  menu = new MenuItensGeral(this.statusService).MENU_ITEMS;
  constructor(private statusService: StatusService,private cookieService: CriptoLocalStorageService) { 
    this.changePath = new ChangePath(this.cookieService);
  }
  ngOnInit(): void {
    this.changePath.changePath(this.menu)
  }
  ngOnDestroy(): void {
    this.changePath.changePathOnDestroy(this.menu)
  }
}
