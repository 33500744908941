import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
}
  from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { SISTEMAS } from '../../enums/sistemas';
import { environment } from '../../../../environments/environment';
import { CriptoLocalStorageService } from '../../services/cripto-localstorage/cripto-localstorage.service';
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private cookieService: CriptoLocalStorageService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var _user = this.cookieService.get('uniqueIdParticipante');
    let _userUniqueId = this.cookieService.get('uniqueIdUsuario');
    let _docParticipante = this.cookieService.get('documentoParticipante');
    let _tipoParticipante = this.cookieService.get('tipoParticipante');
    let dupReq:any;

    if (request.url.startsWith(environment.buscaCepApi)) {
      dupReq = request.clone({
        headers: request.headers
      });
    }else if (request.url.startsWith(environment.salaDisputaBackend) || request.url.startsWith(environment.salaDisputaUteis) || request.url.startsWith(environment.salaDisputaFast)) {
      dupReq = request.clone({
        responseType: 'text',
        headers: request.headers
          .set('participante-id', _user ? _user : '')
          .set('usuario-id', _userUniqueId ? _userUniqueId : '')
          .set('part-doc', _docParticipante ? _docParticipante : '')
          .set('part-tipo', _tipoParticipante ? _tipoParticipante : '')
          .set('Sistema', SISTEMAS.BBMNETLicitacoes)
      });
    }else{
      dupReq = request.clone({
        headers: request.headers
          .set('x-participant-id', (_user ? _user : ""))
          .set('Sistema', SISTEMAS.BBMNETLicitacoes)
      });
    }
    return next.handle(dupReq);
  }
}
