import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { NgxPrintModule } from 'ngx-print';
import { NbCardModule, NbDialogModule, NbButtonModule, NbTooltipModule, NbBadgeModule, NbIconModule, NbAutocompleteModule, NbFormFieldModule, NbInputModule, NbActionsModule, NbContextMenuModule, NbLayoutModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CallbackPipe } from './utils/filter.pipe';
import { FilterPerfisPipe } from './utils/filter-perfis.pipe';
import { FilterSegmentosPipe } from './utils/filter-segmentos.pipe';
import { PaginationComponent } from './../shared/components/pagination/pagination.component';
import { UnidadeMedidaPipe } from './pipes/unidade-medida.pipe';
import { OnlyNumberPipe } from './pipes/only-number.pipe';
import { UserMenuComponent } from './components/user/user.component';
import { UserSetSociedadeComponent } from './components/user-set-sociedade/user-set-sociedade.component';
import { ModalModule } from './components/modal/modal.module';
import { InfoComponent } from './components/info-component/info.component';



@NgModule({
  declarations: [BreadcrumbComponent, CallbackPipe, FilterPerfisPipe, PaginationComponent,  FilterSegmentosPipe, CurrencyFormatPipe, UnidadeMedidaPipe, OnlyNumberPipe,UserMenuComponent,UserSetSociedadeComponent,InfoComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbDialogModule,
    NbButtonModule,
    NgxPrintModule,
    NbTooltipModule,
    NbBadgeModule,
    NbIconModule,
    NbActionsModule,
    NbContextMenuModule,
    NbLayoutModule,
    ModalModule
  ],
  exports: [BreadcrumbComponent, CallbackPipe, FilterPerfisPipe, PaginationComponent, FilterSegmentosPipe, CurrencyFormatPipe, UnidadeMedidaPipe,OnlyNumberPipe,UserMenuComponent, UserSetSociedadeComponent,InfoComponent]
})
export class SharedModule { }
