import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-guia-sociedade',
  templateUrl: './guia-sociedade.component.html',
  styleUrls: ['./guia-sociedade.component.scss']
})
export class GuiaSociedadeComponent implements OnInit { 
  @ViewChild('visualizarInfo') dialogInfo!: TemplateRef<any>;
  dialogInfoRef!: NbDialogRef<any>;
  constructor(private dialogService: NbDialogService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.carregarInfolicitante();
    },1000)
  }

  carregarInfolicitante(){
    this.dialogInfoRef = this.dialogService.open(this.dialogInfo, { closeOnBackdropClick: false, autoFocus: false, context: "" });
  }

  fecharInfo(){
    this.dialogInfoRef.close();
  }

}
