<nb-card class="guia-body">
    <nb-card-header class="header-card">
        <h5 class="text-center branco size16">Sociedade - Cidadão/Acesso direto sem credenciamento</h5>
        <h6 class="text-center branco size14">Use apenas <b>Google Chrome</b>, <b> Opera</b>, <b>Microsoft Edge</b>, e <b>Safari</b> -
            <b class="red">Incompatível com Mozilla FireFox</b>
        </h6>
    </nb-card-header>
    <nb-card-body>
       

        <div class="size12">
            <p class="size12 text-center">“A Bolsa Brasileira de Mercadorias tem um compromisso com a segurança, a agilidade e a transparência nas licitações públicas”.</p>
            
            <p class="size12">
               <b>Para ter acesso aos editais publicados e resultados, pesquise o menu azul “Editais e Processos” ao lado.</b> 
            </p>
        </div>

        <div class="size12">
            <p class="size12">
               <b>Qualquer usuário pode acompanhar forma on-line (em tempo real, sem delay) as licitações eletrônicas realizadas neste Portal BBMNET de forma direta, rápida, simples e gratuita. Acesse a sala de disputa e pesquise no filtro a licitação desejada (pregão, dispensa ou concorrência).</b> 
            </p>
        </div>

        <div class="size12">
            <p class="size12">
               <b>No menu ao lado, o interessado também poderá pesquisar Ata e Documentos, onde terá acesso as atas das sessões, atas de registros de preços, relatórios das sessões das licitações com todas as informações da licitação registradas no Sistema (participantes, valores e mensagens).</b> 
            </p>
        </div>

        <div class="size12">
            <p class="size12">
               <b>Também poderá acessar as solicitações de esclarecimentos, impugnações, documentos de habilitação dos vencedores, recursos e contrarazões e outras informações. </b> 
            </p>
        </div>

        <div class="size12">
            <p class="size12">
               <b>Em caso de dúvidas, entre em contato conosco por meio dos canais de atendimentos disponíveis no portal (telefone, chat, email) 
                O cidadão também poderá optar por receber editais de seu interesse. Basta cadastrar o seu e-mail que enviaremos todos os editais de interesse, por UF, seguimento e ramo de atividade.</b> 
            </p>
        </div>

        

    </nb-card-body>
    <nb-card-footer>
        <div>
            <p class="size12 text-center"><b>O BBMNET licitações está integrado as  Plataformas Transferegov (+Brasil) e PNCP-Portal Nacional de Contratações Públicas.</b></p>
        </div>
    </nb-card-footer>
</nb-card>

<ng-template #visualizarInfo let-data let-ref="dialogInfoRef">
    <nb-card class="info-card">
        <nb-card-header class="header-card">
            <h5 class="text-center branco">Informativo</h5>
        </nb-card-header>
        <nb-card-body>
            <div class="row row-info">
                <div class="col-12">
                    <p><b style="color: red;">ATENÇÃO,</b></p>
                    <p>Estamos atualizando nossos canais de comunicação para melhor atendê-lo. A partir de agora, <b>nosso novo telefone de contato para atendimento será: </b></p>
                    <p style="text-align: center;"><b>(11) 3181-8214.</b></p>
                    <p>Por favor, atualize suas informações e utilize este novo número para entrar em contato conosco. Agradecemos a sua compreensão e continuaremos disponíveis para atender todas as suas necessidades.</p>
                    <p>Equipe BBMNET</p>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-evenly">
            <button nbButton status="danger" size="small" (click)="fecharInfo()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>