import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'unidademedida'
})
export class UnidadeMedidaPipe implements PipeTransform {
  transform(value: string, unidadesDeMedida: any): string {

    if (!unidadesDeMedida || !value) {
        return '';
    }

    const unidadeDeMedida:any = unidadesDeMedida.filter(unidade => unidade.uniqueId === value);
    return unidadeDeMedida[0].descricao;
  }
}