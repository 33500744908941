import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class CriptoLocalStorageService {
    private env = environment;
    private secretKey = this.env.criptoLocalKey;
    constructor(private cookieService: CookieService,private keycloak: KeycloakService) { }

    setItem(key: string, value: any): void {
        const encryptedValue = CryptoJS.AES.encrypt(value, this.secretKey).toString();
        localStorage.setItem(key, encryptedValue);
    }

    getItem(key: string): any {
        const encryptedValue = localStorage.getItem(key);
        if (encryptedValue) {
            const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
            try{
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
                return decryptedValue;
            }catch(e){
                this.logout();
            }
            
        }
        return null;
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    set(key: string, value: any, path?:any): void {
        if(path){
            const encryptedValue = CryptoJS.AES.encrypt(value, this.secretKey).toString();
            this.cookieService.set(key, encryptedValue,path);
        }else{
            const encryptedValue = CryptoJS.AES.encrypt(value, this.secretKey).toString();
            this.cookieService.set(key, encryptedValue);
        }
    }

    get(key: string): any {
        const encryptedValue = this.cookieService.get(key);
        if (encryptedValue) {
            const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
            try{
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
                return decryptedValue;
            }catch(e){
                this.logout();
            }
        }
        return null;
    }

    delete(key: string, path?: string): void {
        if(path){
            this.cookieService.delete(key,path);   
        }else{
            this.cookieService.delete(key);
        }
        
    }


    deleteAll(key:string){
        this.cookieService.deleteAll(key);
    }

    check(key: string){
        return this.cookieService.check(key);
    }

    logout() {
        this.removeItensLocalStorage()
        const redirectUri = this.env.frontRef + '/auth/login';
        this.keycloak.logout(redirectUri);
    }

    removeItensLocalStorage() {
        this.cookieService.delete('tipoParticipante','/');
        this.cookieService.delete('profile','/');
        this.cookieService.delete('documentoParticipante','/');
        this.cookieService.delete('uniqueIdParticipante','/');
        this.cookieService.delete('tipoPessoa','/');
        this.cookieService.delete('nomeParticipante','/');
        this.cookieService.delete('IsUserCorretora','/');
        this.cookieService.delete('uniqueIdUsuario','/');
        this.cookieService.delete('idModalidade','/');
        this.cookieService.delete('nomeParticipante','/');
        this.cookieService.deleteAll('/');
    }
}