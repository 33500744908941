import { Component, Input, OnInit } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { TIPO_PARTICIPANTE } from '../../enums/participante';
import { TIPOS_PARTICIPANTES_USER } from '../../constants/tipoParticipantes';
import { AssinaturasService } from '../../services/assinaturas.service';
import { Assinatura } from '../../models/app-user-menu/app-user-menu.model';
import { CriptoLocalStorageService } from '../../services/cripto-localstorage/cripto-localstorage.service';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserMenuComponent implements OnInit {
  @Input('nomeUser') nomeUser: string;
  @Input('user') user: any;
  @Input('perfilUser') perfilUser: string;

  public userMenu = [{ title: 'Sair' }];

  public env = environment;

  public isOrgaoPromotor = false;
  
  public vigenciaAssinatura;

  constructor(
    private menuService: NbMenuService, 
    private keycloak: KeycloakService,
    protected router: Router,
    private assinaturas: AssinaturasService,
    private cookieService: CriptoLocalStorageService) {

  }




  ngOnInit(): void {
    this.getFimAssinaturaVigencia()
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === "Sair") {
        this.logout();
      }
      if (event.item.title === "Meus dados") {
        this.router.navigateByUrl("/perfil/meusdados");
      }
    });
  }

  logout() {
    let redirectUrl: string = this.env.frontRef;
    this.cookieService.delete("auth_app_token",'/');
    this.removeItensCookies()
    this.keycloak.logout(redirectUrl + '/');
  }

  removeItensCookies() {
    this.cookieService.delete('tipoParticipante','/');
    this.cookieService.delete('profile','/');
    this.cookieService.delete('documentoParticipante','/');
    this.cookieService.delete('uniqueIdParticipante','/');
    this.cookieService.delete('tipoPessoa','/');
    this.cookieService.delete('nomeParticipante','/');
    this.cookieService.delete('IsUserCorretora','/');
    this.cookieService.delete('uniqueIdUsuario','/');
    this.cookieService.delete('idModalidade','/');
    this.cookieService.delete('nomeParticipante','/');
    this.cookieService.delete('nomeParticipante','/');
    this.cookieService.deleteAll('/');
  }

  getFimAssinaturaVigencia(){
    this.assinaturas.getFimAssinaturaVigente(this.user.participanteId).subscribe((res:any)=>{
      if(res){
        let listAssinatura:Array<Assinatura> = res;
        if(listAssinatura.length> 0){
          let filtro = listAssinatura.sort(this.sortAssinatura)
          this.vigenciaAssinatura = filtro[0].dataFim;
        }
      }
    
    })
  }

  sortAssinatura(assinaturaA: Assinatura, assinaturaB: Assinatura){
    return assinaturaA.dataFim < assinaturaB.dataFim ? 1:-1
  }

  verificaMsgVigencia(vigenciaAssinatura){
    let horaAtual = new Date();
    let dataVigente = new Date(vigenciaAssinatura)
    if(horaAtual < dataVigente){
      return "Vigente até: "
    }else{
      return "Expirada em: "
    }
  }

}
