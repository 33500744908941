import { HttpClient, HttpHeaders } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OrgaoPromotorDadosCadastrais } from '../models/orgao-promotor-dados-cadastrais';
import { OrgaoPromotorDadosRepresentante } from '../models/orgao-promotor-dados-representante';

@Injectable({
  providedIn: 'root'
})
export class AssinaturasService {
    public env = environment;

    constructor(private http: HttpClient) { }

    getFimAssinaturaVigente(uniqueIdParticipante: string){
       return this.http.get(this.env.apiCadastroParticipantes + `/api/Assinatura/participante/${uniqueIdParticipante}/DataFimVigencia`)
    }

}