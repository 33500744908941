import { LoadingService } from './loading.service';
import { Injectable } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { NbIconConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private iconError: NbIconConfig = { icon: 'close-circle-outline', pack: 'eva' };

  constructor(private toast: NbToastrService, private loadingService: LoadingService) { }

  validateForm(formGroup: FormGroup | FormArray) {
    let errors = {
      required: false,
      email: false,
      mustMatch: false,
      mustMatchSenha: false,
      mask: false,
      password: false,
      minlength: false,
      maxlength: false,
      containEmail: false
    };

    Object.keys(formGroup.controls).forEach(key => {
      const input = formGroup.get(key);

      if (key == 'email' && input?.value) {
        errors.containEmail = true;
      }

      if (input instanceof FormGroup || input instanceof FormArray) {

        if (input instanceof FormArray) {
          input.controls.forEach((form: any) => {
            this.validateFormArray(form, errors);
          })
        }

      }

      if (input?.errors) {
        if (input.errors.required) {
          errors.required = true;
        }
        if (input.errors.email) {
          errors.email = true;
        }
        if (input.errors.mustMatch) {
          errors.mustMatch = true;
        }
        if (input.errors.pattern) {
          errors.password = true;
        }
        if (input.errors.mustMatchSenha) {
          errors.mustMatchSenha = true;
        }
        if (input.errors.mask) {
          errors.mask = true;
        }
        if (input.errors.minlength) {
          errors.minlength = true;
        }
        if (input.errors.maxlength) {
          errors.maxlength = true;
        }
      }
    });

    if (errors.required) {
      this.errorMessage('Favor preencher todos os campos obrigatórios');
    } if (errors.email) {
      this.errorMessage('Informe um e-mail válido');
    } if (errors.containEmail && errors.mustMatch) {
      this.errorMessage('Os campos e-mail e confirmação do e-mail devem possuir o mesmo conteúdo. Por favor, verifique.');
    } if (errors.mustMatchSenha) {
      this.errorMessage('Os campos senha e confirmação senha devem possuir o mesmo conteúdo. Por favor, verifique.');
    } if (errors.mask) {
      this.errorMessage('Verifique os campos');
    } if (errors.password) {
      this.errorMessage('Senha inválida. Necessário o preenchimento mínimo de 8 caracteres, incluindo número, letra maiúscula, letra minúscula e caractere especial');
    }
    if (errors.minlength) {
      this.errorMessage('Campo não atende o mínimo de caracteres');
    }
    if (errors.maxlength) {
      this.errorMessage('Campo atingiu o limite de caracteres');
    }
  }

  validateFormArray(formArray: FormGroup, errors: any) {
    if(formArray.controls){
      Object.keys(formArray.controls).forEach(key => {
        const input = formArray.get(key);
        
  
        if (key == 'email' && input?.value) {
          errors.containEmail = true;
        }
  
        if (input?.errors) {
          if (input.errors.required) {
            errors.required = true;
          }
          if (input.errors.email) {
            errors.email = true;
          }
          if (input.errors.mustMatch) {
            errors.mustMatch = true;
          }
          if (input.errors.pattern) {
            errors.password = true;
          }
          if (input.errors.mustMatchSenha) {
            errors.mustMatchSenha = true;
          }
          if (input.errors.mask) {
            errors.mask = true;
          }
          if (input.errors.minlength) {
            errors.minlength = true;
          }
          if (input.errors.maxlength) {
            errors.maxlength = true;
          }
        }
      });
    }
 
  }

  checkErrors(error: any, form?: FormGroup | undefined) {
    if (error?.error?.errors) {

      const arrayErrors = error?.error?.errors;

 

      Object.keys(arrayErrors).forEach((key: any) => {
        
        if(arrayErrors[key].length > 1) {
          arrayErrors[key].forEach(erroMsg => {
            this.errorMessage(erroMsg);
          });
          
        } else {
          this.errorMessage(arrayErrors[key]);
        }
        const myControl = JSON.parse(JSON.stringify(key)).toLowerCase();
        form?.controls[myControl]?.setErrors(Validators.nullValidator);
      });
    } else {
      this.errorMessage('Erro inesperado');
    }

    this.loadingService.hide();

  }

  public errorMessage(message: string, duration:number = 6000) {
    this.toast.danger(message, 'Temos um problema', { duration, icon: this.iconError });
  }

  public succesMessage(message: string) {
    this.toast.success(message, 'Sucesso', {duration: 6000});
  }
  
  public alertMessage(message: string) {
    this.toast.warning(message, 'Alerta',{duration: 8000});
  }

  public infoMessage(message: string) {
    this.toast.info(message, 'Info',{duration: 8000});
  }

  public controlMessage(message: string) {
    this.toast.control(message, 'Alerta',{duration: 8000});
  }
}



