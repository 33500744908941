import { Injectable } from '@angular/core';
import { CriptoLocalStorageService } from './cripto-localstorage/cripto-localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private cookieService: CriptoLocalStorageService) { }

  participanteVisualizacaoEditais(): boolean {
    const tipoParticipante = this.cookieService.get("tipoParticipante");

    return tipoParticipante.toLowerCase() !== 'orgaoPromotor'.toLowerCase() && tipoParticipante.toLowerCase() !== 'unidadecompradora'.toLowerCase();
  }

  getTipoParticipante(): string {
    const tipoParticipante = this.cookieService.get("tipoParticipante");
    return tipoParticipante;
  }

  getPerfilParticipante(): string {
    const perfilParticipante = this.cookieService.get("perfilParticipante");
    return perfilParticipante;
  }

  getStatus(status: string): string {
    let newStatus;
    switch (status) {
      case 'AguardandoPagamento':
        newStatus = 'Aguardando pagamento';
        break;
      case 'AguardandoAprovacao':
        newStatus = 'Aguardando aprovação';
        break;
      default:
        newStatus = status;
        break;
    }

    return newStatus;
  }

  isEdit(status: string): boolean {
    if (this.participanteVisualizacaoEditais()) return false;

    return (status == 'Não Publicado' || status == 'Publicado' || status == 'Rascunho' || status == 'AguardandoAprovacao' || status == 'Não Publicado' || status == 'Erro Processamento de Lotes' || status == 'Processando Lotes') ? true : false;
  }
  isView(status: string): boolean {

    return (status == 'Negociado' || status == 'Não Publicado' || status == 'Rascunho' || status == 'Publicado' || status == 'Erro Processamento de Lotes' || status == 'Processando Lotes') ? true : false;
  }

  isCopy(status: string): boolean {
    if (this.participanteVisualizacaoEditais()) return false;

    return (status == 'Negociado' || status == 'Não Publicado' || status == 'Publicado') ? true : false;
  }
  isDelete(status: string): boolean {
    if (this.participanteVisualizacaoEditais()) return false;

    return (status == 'Não Publicado' || status == 'Rascunho' || status.toLowerCase() == 'Erro Processamento de Lotes'.toLowerCase()) ? true : false;
  }

  isAprove(status: string): boolean {
    return (status == 'Não Publicado') ? true : false;
  }

  isErroLote(status: string): boolean {
    return (status == 'Erro Processamento de Lotes') ? true : false;
  }

  isPublished(status: string){
    return (status == 'Publicado') ? true : false;
  }

  // isActivate(status:string): boolean {
  //   return (status == 'Não Publicado' || status == 'Inativo') ? true : false;
  // }


}
