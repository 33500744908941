import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ACTION_BUTTON } from '../../enums/action_button.enum';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input('modalId') modalId: any;
  @Input('uniqueIdEdital') uniqueIdEdital: any;
  @Input('lotes') lotes: any;

  constructor(protected ref: NbDialogRef<ModalComponent>) { }

  ngOnInit(): void {
  }

  closeModal(event){
    if(event == ACTION_BUTTON.closemodal){
      this.ref.close();
    }
  }

}
