import { RouterModule } from '@angular/router';
import { UsuarioComponent } from './usuario.component';
import { NbCardModule, NbButtonModule, NbTooltipModule, NbOptionModule, NbFormFieldModule, NbLayoutModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NbRadioModule,
  NbSelectModule,
  NbInputModule, 
  NbIconModule} from '@nebular/theme';
import { UsuarioRoutingModule } from './usuario-routing.module';
import { ThemeModule } from '../../../@theme/theme.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [UsuarioComponent],
  imports: [
    CommonModule,
    UsuarioRoutingModule,
    ThemeModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbRadioModule,
    FormsModule,
    ReactiveFormsModule,
    NbFormFieldModule,
    NbSelectModule,
    NbTooltipModule,
    NbLayoutModule
  ]
})
export class UsuarioModule { }
