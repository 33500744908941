import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input('nomeComponent') nomeComponent: string = "";
  constructor() {}
  ngOnInit(): void {}
  
  downloadArquivo(){
    window.open(location.origin + '/assets/Opiniao_Legal.Prazo_para_impugnacao_ao_edital_ou_pedido_de_esclarecimento.pdf');
  }
}
