export enum CONCORRENCIA {
  Id = "1",
  Name = "Concorrência"
}

export enum DIALOGO_COMPETITIVO {
  Id = "2",
  Name = "Diálogo Competitivo"
}

export enum PREGAO {
  Id = "3",
  Name = "Pregão (Setor público)"
}

export enum LEILAO {
  Id = "4",
  Name = "Leilão"
}

export enum DISPENSA {
  Id = "5",
  Name = "Dispensa"
}

export enum CREDENCIAMENTO {
  Id = "6",
  Name = 'Credenciamento'
}


export const MODALIDADES = {
  CONCORRENCIA,
  DIALOGO_COMPETITIVO,
  PREGAO,
  LEILAO,
  DISPENSA,
  CREDENCIAMENTO
}
