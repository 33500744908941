import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TIPO_PARTICIPANTE } from '../../enums/participante';
import { TIPOS_PARTICIPANTES_USER } from '../../constants/tipoParticipantes';
import { CriptoLocalStorageService } from '../../services/cripto-localstorage/cripto-localstorage.service';


@Component({
  selector: 'app-user-set-sociedade',
  templateUrl: './user-set-sociedade.component.html',
  styleUrls: ['./user-set-sociedade.component.scss']
})
export class UserSetSociedadeComponent implements OnInit {
  @Input('listParticipantes') listParticipantes$: Observable<any>
  @Input('isSociedade') isSociedade$: Observable<any>
  public disableButton = true;
  public checked = false;
  public usuarioUniqueId: string;
  public listaPaticipantesArray: Array<any> = [];

  constructor(
    private keycloak: KeycloakService,
    protected router: Router,
    private cookieService: CriptoLocalStorageService) {

  }



  // toggle(checked: boolean) {
  //   this.checked = checked;
  // }


  ngOnInit(): void {
    this.usuarioUniqueId = this.keycloak.getKeycloakInstance().idTokenParsed.sub;
    let tipoParticipante = this.cookieService.get('tipoParticipante');
    if (tipoParticipante == TIPOS_PARTICIPANTES_USER.SOCIEDADE) {
      this.checked = true;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.listParticipantes$?.currentValue) {
    //   this.listParticipantes$ = of(changes.listParticipantes$.currentValue);
    //   this.listParticipantes$.subscribe((res: Array<any>) => {
    //     if (res.length > 0) {
    //       this.listaPaticipantesArray = res
    //       this.disableButton = false;
    //     }
    //   })
    // }

    if (changes?.isSociedade$?.currentValue) {
      this.isSociedade$ = of(changes.isSociedade$.currentValue);
      this.isSociedade$.subscribe((value) => {
        if(value){
          this.SetSociedade();
        }
      })

    }

  }
  SetSociedade() {
    // console.log('SetSociedade')
    // let participanteUniqueId = this.listaPaticipantesArray[0]?.participante?.uniqueId
    // if (this.checked) {
    //   if (participanteUniqueId) {
    //     this.removeItensLocalStorage();
    //     this.router.navigateByUrl('/controller/' + participanteUniqueId);
    //   }
    // } else {
      this.removeItensLocalStorage();
    
    // }

    // this.checked == true? this.checked = false : this.checked = true;
  }

  removeItensLocalStorage() {
    this.cookieService.delete('tipoParticipante', '/');
    this.cookieService.delete('documentoParticipante', '/');
    this.cookieService.delete('uniqueIdParticipante', '/');
    this.cookieService.delete('uniqueIdUsuario', '/');
    this.cookieService.delete('tipoPessoa', '/');
    this.cookieService.delete('nomeParticipante', '/');
    this.cookieService.delete('nomeParticipante', '/');
    this.cookieService.delete('IsUserCorretora', '/');
    this.cookieService.delete('perfilParticipante', '/');
    this.cookieService.deleteAll("/");
    this.setSociedadeLocalStorage();
  }

  setSociedadeLocalStorage() {
    this.cookieService.set('tipoParticipante', TIPOS_PARTICIPANTES_USER.SOCIEDADE, { path: "/" });
    this.cookieService.set('documentoParticipante', TIPOS_PARTICIPANTES_USER.SOCIEDADE, { path: "/" });
    this.cookieService.set('uniqueIdParticipante', this.usuarioUniqueId, { path: "/" });
    this.cookieService.set('uniqueIdUsuario', this.usuarioUniqueId, { path: "/" });
    this.cookieService.set('tipoPessoa', TIPOS_PARTICIPANTES_USER.SOCIEDADE, { path: "/" });
    this.cookieService.set('perfilParticipante', TIPOS_PARTICIPANTES_USER.SOCIEDADE, { path: "/" });
    this.cookieService.set('nomeParticipante', TIPOS_PARTICIPANTES_USER.SOCIEDADE, { path: "/" });
    this.cookieService.set('IsUserCorretora', 'false', { path: "/" });
    this.cookieService.set('perfilParticipante', TIPOS_PARTICIPANTES_USER.SOCIEDADE, { path: "/" });
    this.router.navigateByUrl('/controller');
  }
}
