import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-guia-pregoeiro',
  templateUrl: './guia-pregoeiro.component.html',
  styleUrls: ['./guia-pregoeiro.component.scss']
})
export class GuiaComponentPregoeiro implements OnInit {

  @ViewChild('visualizarVideo') dialogVideo!: TemplateRef<any>;
  dialogRef!: NbDialogRef<any>;

  @ViewChild('visualizarInfo') dialogInfo!: TemplateRef<any>;
  dialogInfoRef!: NbDialogRef<any>;
  
  constructor(private dialogService: NbDialogService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.carregarInfoPregoeiro();
    },1000)
  }

  carregarInfoPregoeiro(){
    this.dialogInfoRef = this.dialogService.open(this.dialogInfo, { closeOnBackdropClick: false, autoFocus: false, context: "" });
  }

  fecharInfo(){
    this.dialogInfoRef.close();
  }

  abriImgInfo(){
    window.open(location.origin + '/assets/images/imageFeedback.png');
  }

  baixarDocumento(){
    window.open(location.origin + '/assets/LayoutArquivoXLSX_2.xlsx');
  }

  mostrarVideo(value){
    let dados;
    if(value == 1){
      dados = {
        videoDescricao: "Como cadastrar um Edital",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2Fpregoeiro%2F1-DadosdoEdital.mp4?alt=media&token=e53222a4-9e51-4ca2-b0a5-b62839960859'
      }
    } else if (value == 2){
      dados = {
        videoDescricao: "Como cadastrar manualmente",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2Fpregoeiro%2F2-DadosdoLote-Item(manual).mp4?alt=media&token=61fac770-42e3-4aa2-9bde-39a62648ad9f'
      }
    } else if (value == 3){
      dados = {
        videoDescricao: "Como preparar uma planilha excel para criar os lotes e itens",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2Fpregoeiro%2F3-DadosdoLote-Item(Preparando%20Planilha).mp4?alt=media&token=071ad418-699e-40a7-a563-fa502665cc7b'
      }
    }
    else if (value == 4){
      dados = {
        videoDescricao: "Como cadastrar por meio de upload de planilha excel",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2Fpregoeiro%2F4-DadosdoLote-Item(Importando%20Arquivos).mp4?alt=media&token=88bb5238-d490-450a-a325-0790bd8db408'
      }
    }
    else if (value == 5){
      dados = {
        videoDescricao: "Como anexar os documentos do Edital(Edital e Anexos)",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2Fpregoeiro%2F5-DocumentosEditaleanexos.mp4?alt=media&token=a45414df-56d1-415a-ab5f-33fb371214f6'
      }
    }
    else if (value == 6){
      dados = {
        videoDescricao: "Como Editar, Visualizar, Copiar, Excluir ou Publicar um Edital",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2Fpregoeiro%2F6-Comoeditar_visualizar_copiar_excluiroupublicarumedital.mp4?alt=media&token=1a41378b-353f-4dd5-be75-3f90cd10d8ad'
      }
    }
    this.dialogRef = this.dialogService.open(this.dialogVideo, { closeOnBackdropClick: false, autoFocus: false, context: dados });
  }

  fecharVideo(){
    this.dialogRef.close();
  }

}
