import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DadosListaRelatorioSintetico, RelatorioModelData } from '../../../../shared/models/relatorio-comissao';
import { RelatorioComissaoService } from '../../../../shared/services/relatorio-comissao.service';
import { jsPDF } from "jspdf";
import  html2canvas  from 'html2canvas';
@Component({
  selector: 'ngx-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaRelatorioComponent implements OnInit {
  @ViewChild('dialogAnalitico') dialogAnalitico!: TemplateRef<any>;
  @ViewChild('autoInput') input!: any;
  @ViewChild('relatorioSintetico') relatorioSintetico!:  TemplateRef<any>;

  dialogRef!: NbDialogRef<any>;
  orgaoPromotor:Array<any> = [];
  date = new Date();
  public filtersForm!: FormGroup;
  filteredOptions$!: Observable<any[]>;
  public corretoras: Array<any> = [];
  public listaOP: Array<any> = [];
  public listaMes: Array<any> = [];
  public listaAno: Array<any> = [];
  public listaDadosRelSin: Array<DadosListaRelatorioSintetico> = [];
  public tipoRelatorio:string = "";


  constructor(
    private dialogService: NbDialogService,
    private relatorioService: RelatorioComissaoService,
    private formBuilder: FormBuilder,
    ) {

  }

  private createForm() {
    this.filtersForm = this.formBuilder.group({
      mes: [],
      ano: [],
      corretora: [],
      orgaoPromotorUniqueId:[],
      tipoRelatorio:[1]
    });
    this.getCorretorasAtivas()
  }

  ngOnInit(): void {
    this.createForm()
    this.montarAno();
    this.montarMes();

  }

  visualizarDOC(relatorioToPDF){
    this.dialogRef = this.dialogService.open(this.relatorioSintetico, {closeOnBackdropClick: false, autoFocus: false,hasScroll:true});
  }

  gerarArquivoPDF(relatorioToPDF){
    let data =  document.getElementById(relatorioToPDF); 
    html2canvas(data, { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
       pdf.save("HTML-Document.pdf");
    });
   
  }


  

  getCorretorasAtivas(){
    this.relatorioService.getCorretorasAtivas().subscribe((corretoras:any[])=>{
      this.corretoras = corretoras;
      this.filteredOptions$ = this.getFilteredOptions(this.filtersForm.value.corretora);
     
    })
  }


  confirmarAtivacao(idDado: any) {
    const modalContent = {
      titulo: 'Confirmação',
      mensagem: "TESTE",
      acao: () => {

      }
    };
    this.dialogRef = this.dialogService.open(this.dialogAnalitico, {closeOnBackdropClick: false, autoFocus: false,hasScroll:true});
  }

  getRelatorios(){
    let filter = this.filtersForm.value;

    let dados:RelatorioModelData = new RelatorioModelData;
    dados.Ano = 2022;
    dados.Mes = 4;
    dados.CorretoraUniqueId = '2d718775-b0d6-4c65-af18-ecaa6267254b';
    dados.OrgaoPromotorUniqueId = 'f99fee39-4f5f-45b5-b917-fd279396f708';
    this.relatorioService.getRelatorioComissaoSinteticoGanho(dados).subscribe((res)=>{

      this.tipoRelatorio = filter.tipoRelatorio == 1?"Ganhos":"Demais transações";
      this.listaDadosRelSin = res
    })

  }
  // função pra NBAUTOINPUT
  onSelectionChange($event: any) {
    let corretoraSelecioada = $event.uniqueId;
    this.listaOP = [];
    this.resetSelecao();
    this.relatorioService.getOrgaoPromotorPorCorretora(corretoraSelecioada).subscribe((orgaosPromotores:any[])=>{
      this.listaOP = orgaosPromotores;
    })
  }

  resetSelecao(){
    this.filtersForm.controls.orgaoPromotorUniqueId.reset();
  }
  
  getFilteredOptions(value: any): Observable<any[]> {
    if(value) {
      return of(value).pipe(
        map(filterString => this.filter(filterString)),
      );
    } else {
      return of(this.corretoras);
    }
    
  }

  private filter(value: any): any[] {
    const filterValue = value.nome ? value.nome.toLowerCase() : value.toLowerCase();
    return this.corretoras.filter((optionValue: any) => optionValue.nome.toLowerCase().includes(filterValue));
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.filtersForm.value.corretora);
  }

  viewHandle(value: any) {
    return value.nome ? value.nome 
    : value;
  }
  //FIM de  função pra NBAUTOINPUT


  montarMes(){
    this.listaMes = [
      {
        mes:"Janeiro",
        value: 1
      },
      {
        mes:"Fevereiro",
        value: 2
      },
      {
        mes:"Março",
        value: 3
      },
      {
        mes:"Abril",
        value: 4
      },
      {
        mes:"Maio",
        value: 5
      },
      {
        mes:"Junho",
        value: 6
      },
      {
        mes:"Julho",
        value: 7
      },
      {
        mes:"Agosto",
        value: 8
      },
      {
        mes:"Setembro",
        value: 9
      },
      {
        mes:"Outubro",
        value: 10
      },
      {
        mes:"Novembro",
        value: 11
      },
      {
        mes:"Dezembro",
        value: 12
      }
    ]

  }
  montarAno(){
    this.listaAno = [
      2022,
      2023,
      2024,
      2025,
      2026
    ]

  }
}
