import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbConfig:any = [];

  public pos = 0;

  @Input ('config') set config(config:any) {
    this.breadcrumbConfig = config;
  }

  @Input('position') set position(newPosition: any) {
    this.pos = newPosition;
 
  }
  


  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  
  }

  verifyActive(url: string) {

 
    return url === this.activatedRoute.snapshot.routeConfig?.path;
  }



}
