import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LicitanteService {

  public naturezaOrgaoPromotor: any;
  public env = environment
  public nomeParticipante: Subject<string> = new Subject<string>();
  public nomeParticipantePF: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) { }

  public setParticipante(participante: any) {
    this.nomeParticipante.next(participante.nomeFantasia);
  }

  public setParticipantePF(participante: any) {
    this.nomeParticipantePF.next(participante.nome);
  }

  public adicionarUsuarioLicitante(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/licitante/registrar', body);
  }

  public getTiposPessoas(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '​/api/cadastro/participante/tipos');
  }

  public getTiposEmpresa(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/tipoempresa');
  }

  public getCargos(tipoEmpresaId:any): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/tipoempresa/'+tipoEmpresaId+'/cargo');
  }

  public adicionarDadosCadastraisPFPreCadastro(body:any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/pf', body)
  }

  public adicionarDadosCadastraisEstrangeiroPreCadastro(body:any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/estrangeiro', body)
  }

  public atualizarDadosCadastraisEstrangeiroPreCadastro(body:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/estrangeiro', body)
  }

  public atualizarDadosCadastraisEstrangeiro(body:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/Licitante/estrangeiro', body)
  }

  public atualizarDadosCadastraisPFPreCadastro(body:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/pf', body)
  }

  public atualizarDadosCadastraisPF(body:any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/Licitante/pf', body)
  }

  public adicionarDadosCadastraisPJPreCadastro(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/pj', body)
  }

  public atualizarDadosCadastraisPJPreCadastro(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/pj', body)
  }

  public atualizarDadosCadastraisPJCadastro(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/pj', body)
  }

  public getLicitantePorDocumento(documento: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/pf/' + documento);
  }

  public getLicitantePJPorDocumento(documento: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/pj/' + documento);
  }

  public getLicitanteEstrangeiroPorDocumento(documento: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/estrangeiro/' + documento);
  }

  public getLicitanteEstrangeiroPorId(id: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/estrangeiro/' + id);
  }

  public getRamosDeAtividade(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/RamoAtividade/Segmento');
  }

  public getSegmentosPorId(id:any): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/RamoAtividade/Segmentos/'+id);
  }

  public getSegmentos(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + 'api/RamoAtividade/Segmento');
  }

  public adicionarRepresentanteLegal(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/representante', body);
  }

  public adicionarSocio(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/licitante/socio', body);
  }

  public deletarRepresentanteLegal(id: any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/representante/' + id);
  }

  public deletarSocio(id: any): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/PreCadastro/licitante/socio/' + id);
  }

  public adicionarUsuariosLicitantePreCadastro(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/licitante/usuario', body);
  }

  public deleteUsuarioPreCadastro(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/precadastro/licitante/usuario/' + idUsuario);
  }

  public getPerfisLicitante(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/perfil/licitante');
  }

  public finalizarCadastroLicitante(idParticipnte: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/precadastro/licitante/' + idParticipnte + '/finalizar', {});
  }

  public getTiposParticipantes(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/participante/tipos');
  }

  public getLicitantesPJ(filters?: any): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/licitante/pj' + this.parseFilters(filters));
  }

  public getLicitantesPf(filters?: any): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/licitante/pf' + this.parseFilters(filters));
  }

  public changeStatus(body: any): Observable<any> {
    return  this.http.put(this.env.apiCadastroParticipantes + '/api/admin/licitante/status', body);
  }

  public aprovarLicitante(id: string): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/licitante/aprovar/' + id, {});
  }

  public adicionarDadosCadastraisPJ(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/licitante/pj', body)
  }

  public adicionarDadosCadastraisAdminPF(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/licitante/pf', body)
  }

  public atualizarDadosCadastraisAdminPF(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/licitante/pf', body)
  }

  public atualizarDadosCadastraisPJ(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/licitante/pj', body)
  }

  public getLicitantePJPorId(documento: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/pj/' + documento);
  }

  public getLicitantePFPorId(documento: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/licitante/pf/' + documento);
  }

  private parseFilters(filters?: any) {
		if (filters) {
			const filter_keys = Object.keys(filters);
			if (filter_keys.length) {
				const filter_array: string[] = [];
				filter_keys.map((filter: string) => {
					if (filters[filter] !== null && filters[filter] !== undefined) {
						filter_array.push(`${filter}=${filters[filter]}`);
					}
				});
				const filter_string = `?${filter_array.join('&')}`;
				return filter_string;
			} else {
				return '';
			}
		} else {
			return '';
		}
	}



  

}
