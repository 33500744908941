import { PipeTransform, Pipe } from '@angular/core';
import { EMPTY } from 'rxjs';

@Pipe({
    name: 'callback'
})
export class CallbackPipe implements PipeTransform {
    transform(items: any[], id: number): any {
        if (!items || !id) {
            return '';
        }

        const item:any = items.filter(item => item.id === id);
 
        return item[0].segmentos;
    }
}