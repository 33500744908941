import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'only-number'
})
export class OnlyNumberPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        return value.replace(/[^0-9]/g,"")
    }
  }
