export class RelatorioModelData {
    Mes?: number;
    Ano?: number;
    CorretoraUniqueId?: string;
    OrgaoPromotorUniqueId?: string;
}
export class DadosListaRelatorioSintetico {
    ano?:number;
    mes?:number;
    nomeOrgaoPromotor?:string;
    orgaoPromotorId?:string;
    quantidadePregoesRealizados?:number;
    servicoContratadoHonorariosDevidos?:number;
    totalPeriodoRessarcimentoCustos?:number;
}