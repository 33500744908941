import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { GuiaComponent } from './guia/guia.component';
import { GuiaComponentPregoeiro } from './guia-pregoeiro/guia-pregoeiro.component';
import { RolesGuard } from '../../shared/guards/roles.guard';
import { GuiaSociedadeComponent } from './guia_sociedade/guia-sociedade.component';


const routes: Routes = [
  { path: '', component: HomeComponent,
    children: [
      // { path: '', redirectTo: 'guia', pathMatch: 'full' },
      {
        path: 'guia',
        component: GuiaComponent,
        canActivate: [RolesGuard],
        data: { roles: ['licitante'] }
      },
      {
        path: 'guia-pregoeiro',
        component: GuiaComponentPregoeiro,
        canActivate: [RolesGuard],
        data: { roles: ['orgaopromotor'] }
      },
      {
        path: 'guia-sociedade',
        component: GuiaSociedadeComponent,
        canActivate: [RolesGuard],
        data: { roles: ['sociedade'] }
      },
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
