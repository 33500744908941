import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OrgaoPromotorDadosCadastrais } from '../models/orgao-promotor-dados-cadastrais';
import { OrgaoPromotorDadosRepresentante } from '../models/orgao-promotor-dados-representante';

@Injectable({
  providedIn: 'root'
})
export class OrgaoPromotorService {

  public naturezaOrgaoPromotor: any;
  public env = environment;
  public nomeParticipante: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) { }

  public getParticipantesUsuario(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Usuario/' + idParticipante + '/participantes');
  }

  public setParticipante(participante: any) {
    this.nomeParticipante.next(participante.nomeFantasia);
  }

  public adicionarUsuarioOrgaoPromotor(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor/registrar', body);
  }

  public getNaturezas(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/natureza');
  }

  public adicionarDadosCadastrais(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/PreCadastro/orgaopromotor', body);
  }

  public atualizarDadosCadastrais(body: any, isEdit: boolean): Observable<any> {
    return isEdit ? this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor', body) : this.http.put(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor', body);
  }

  public getOrgaoPromotor(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/OrgaoPromotor/orgao-logado');
  }

  public getOrgaoPromotorPorDocumento(documento: any): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/' + documento);
  }

  public adicionarDadosRepresentante(body: OrgaoPromotorDadosRepresentante): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor/representante', body);
  }

  public editarDadosRepresentante(body: OrgaoPromotorDadosRepresentante): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor/representante', body);
  }

  public getCargos(id: number): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/natureza/' + id + '/cargo');
  }

  public adicionarUsuariosPreCadastro(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/PreCadastro/usuario', body);
  }

  public adicionarUsuarioPreCadastroOrgaoPromotor(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor/usuario', body);
  }


  public getPerfis(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Perfil');
  }

  public getPerfisOrgaoPromotor(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/perfil/orgaopromotor');
  }

  public deleteUsuarioPreCadastro(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor/usuario/' + idUsuario);
  }

  public deleteUsuarioCadastro(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/usuario/' + idUsuario);
  }

  public finalizarCredenciamento(idRepresentante: string): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/precadastro/orgaopromotor/finalizar/' + idRepresentante, {});
  }


  public getUnidadesCompradoras(idOrgao: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/' + idOrgao + '/unidadecompradora');
  }


  public mudarStatusUnidadeCompradora(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/unidadecompradora/status', body);
  }

  public adicionarUnidadeCompradora(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/cadastro/UnidadeCompradora', body);
  }

  public deleteUnidadeCompradora(idUnidade: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/cadastro/UnidadeCompradora/' + idUnidade);
  }

  public editarUnidadeCompradora(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/unidadecompradora', body);
  }

  public getUnidadeCompradoraPorId(idUnidade: string): Observable<any> {

    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/UnidadeCompradora/' + idUnidade);
  }

  public getUsuariosUnidadesCompradoras(idUnidade: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/UnidadeCompradora/' + idUnidade + '/usuario');
  }

  // public getOrgaoPromotorEdital(documentoParticipante: string): Observable<any> {
  //   return this.http.get(this.env.apiCadastroParticipantes + '/api/licitacoes/orgaoPromotor/' + documentoParticipante);
  // }

  public getOrgaosPromotores(): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/credenciamento/orgaopromotor/editaispublicados');
  }

  public getOrgaosPromotoresEditalAvulso(): Observable<any> {
    return this.http.get(this.env.salaDisputaUteis + '/utils/get-op-com-edital-avulso');
  }

  public getEditalAvulso(filters: any): Observable<any> {
    return this.http.get(this.env.salaDisputaUteis + '/utils/get-editais-avulso-com-licitante'+ this.parseFilters(filters));
  }

  public getEditalAvulsoOP(filters: any): Observable<any> {
    return this.http.get(this.env.salaDisputaUteis + '/utils/get-editais-avulso'+ this.parseFilters(filters));
  }

  public getParticipantesEditalAvulso(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/Participante/listagem',body);
  }

  public getListagemDataCompleteOP(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/credenciamento/orgaopromotor/listagem',body);
  }
  
  async getOrgaoPromotorEdital(docLicitante: string, token: string) {
    const response = await fetch(`${this.env.apiCadastroParticipantes}/api/Licitacoes/orgaopromotor`,
    {
      headers: {
        'documento': docLicitante,
        'Authorization': `Bearer ${token}`
      }
    })
    return response.json();
  }

  public getEditalforOrgaoPromotor(orgaoPromotorUniqueId:string): Observable<any>{
    return this.http.get(this.env.salaDisputaUteis + `/utils/get-editais-avulso?orgaoPromotorId=${orgaoPromotorUniqueId}`);
  }

  private parseFilters(filters?: any) {
    if (filters) {
        const filter_keys = Object.keys(filters);
        if (filter_keys.length) {
            const filter_array: string[] = [];
            filter_keys.map((filter: string) => {
                if (filters[filter] !== null && filters[filter] !== undefined) {
                  filter_array.push(`${filter}=${filters[filter]}`);
                }
            });
            const filter_string = `?${filter_array.join('&')}`;
            return filter_string;
        } else {
            return '';
        }
    } else {
        return '';
    }
  }
}
