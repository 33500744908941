<nb-card class="guia-body">
    <nb-card-header class="header-card">
        <h5 class="text-center branco size16">Guia Rápido: para o pregoeiro cadastrar e publicar editais</h5>
    </nb-card-header>
    <nb-card-body>
        <h6 class="text-center size14">Use apenas <b>Google Chrome</b>, <b> Opera</b>, <b>Microsoft Edge</b>, e <b>Safari</b> -
            <b class="red">Incompatível com Mozilla FireFox</b>
        </h6>

        <div class="row">
            <div class="col-6">
                <div class="size12 marginBottomPassos">
                    <p class="size12"><nb-icon icon="arrow-right" status="primary"></nb-icon><b>1º Passo:</b> Como cadastrar um Edital</p>
                    <div class="row justify-content-between marginLefVideos">
                        <div>
                            <img nbTooltip="Como cadastrar um Edital" class="img_video linkModal" (click)="mostrarVideo(1)"  src="../../../assets/images/img_video.png" alt="img_video">
                        </div>
                    </div>
        
                </div>
        
                <div class="size12 marginBottomPassos">
                    <p class="size12"><nb-icon icon="arrow-right" status="primary"></nb-icon> <b>2º Passo:</b> Como cadastrar o(s) e/ou item(ens) do Edital</p>
        
                    <div class="row justify-content-between marginLefVideos" style="align-items: center;">
                        <div class="marginBottomPassos">
                            <img nbTooltip="Vídeo como cadastrar manualmente" class="img_video linkModal" (click)="mostrarVideo(2)"  src="../../../assets/images/img_video.png" alt="img_video">
                        </div>
                        
                        <div class="marginBottomPassos">
                            <img nbTooltip="Vídeo como preparar uma planilha excel para criar os lotes e itens" class="img_video linkModal" (click)="mostrarVideo(3)"  src="../../../assets/images/img_video.png" alt="img_video">
                            <button nbButton status="primary" class="marginLefVideos" size="small" (click)="baixarDocumento()" nbTooltip="Faça aqui o download da planilha"> <nb-icon icon="download-outline"></nb-icon></button>
                        </div>

                     
                        <div class="marginBottomPassos">
                            <img nbTooltip="Vídeo como cadastrar por meio de upload de planilha excel" class="img_video linkModal" (click)="mostrarVideo(4)"  src="../../../assets/images/img_video.png" alt="img_video">
                        </div>
                     
                    </div>
        
                </div>
        
                <div class="marginBottomPassos">
                    <p class="size12"><nb-icon icon="arrow-right" status="primary"></nb-icon><b>3º Passo:</b> Como anexar os documentos do Edital(Edital e Anexos)</p>
                    <div class="row justify-content-between marginLefVideos">
                        <div class="col-4">
                            <img nbTooltip="Como anexar os documentos do Edital(Edital e Anexos)" class="img_video linkModal" (click)="mostrarVideo(5)"  src="../../../assets/images/img_video.png" alt="img_video">
                        </div>
                    </div>
                    
                </div>
                <div class="marginBottomPassos">
                    <p class="size12"><nb-icon icon="arrow-right" status="primary"></nb-icon><b>4º Passo:</b> Como Editar, Visualizar, Copiar, Excluir ou Publicar um Edital</p>
                    <div class="row justify-content-between marginLefVideos">
                        <div class="col-4">
                            <img nbTooltip="Como Editar, Visualizar, Copiar, Excluir ou Publicar um Edital" class="img_video linkModal" (click)="mostrarVideo(6)"  src="../../../assets/images/img_video.png" alt="img_video">
                        </div>
                    </div>
                    
                </div>
        
            </div>
            <div class="col-6 msg-segunda-coluna">
                <p><b>Nesta página estamos disponibilizando 6 vídeos-manuais com instruções para os pregoeiros e agentes de contratações operarem o BBMNET.</b></p>
                
                <p><b>1º Passo:</b> Como cadastrar um edital</p>
                <p><b>2º Passo:</b> Como cadastrar os itens-lotes do edital de forma manual
                    Como utilizar a planilha para preparar os cadastros dos itens-lotes com o modelo da planilha que deve ser utilizada 
                    Como fazer o upload da planilha com os itens-lotes preenchidos</p>
                <p><b>3º Passo:</b> Como anexar os documentos (Editais e Anexos) antes da publicação </p>
                <p><b>4º Passo:</b> Como editar, visualizar, excluir e/ou publicar um edital</p>
                <p style="color: red;">Em caso de dúvidas entre em contato com os nossos canais de atendimento.</p>
                <p>A Plataforma BBMNET está integrada ao PNCP e ao Transferegov (Plataforma Mais Brasil) do Governo Federal</p>
                    

                    

                    

                    




                    


                    

            </div>

        </div>

      

    </nb-card-body>
</nb-card>

<ng-template #visualizarVideo let-data let-ref="dialogVideoRef">
    <nb-card>
        <nb-card-header class="header-card">
            <h5 class="text-center branco"> {{data.videoDescricao}}</h5>
        </nb-card-header>
        <nb-card-body>
            <video oncontextmenu="return false;" id="meuVideo" width="auto" height="400px" controls="controls">
                <source [src]="data.url" type="video/mp4">
            </video>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-evenly">
            <button nbButton status="danger" size="small" (click)="fecharVideo()">Fechar video</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #visualizarInfo let-data let-ref="dialogInfoRef">
    <nb-card class="info-card">
        <nb-card-header class="header-card">
            <h5 class="text-center branco">Informativo</h5>
        </nb-card-header>
        <nb-card-body>
            <div class="row row-info">
                <div class="col-12">
                    <p><b style="color: red;">ATENÇÃO,</b></p>
                    <p>Estamos atualizando nossos canais de comunicação para melhor atendê-lo. A partir de agora, <b>nosso novo telefone de contato para atendimento será: </b></p>
                    <p style="text-align: center;"><b>(11) 3181-8214.</b></p>
                    <p>Por favor, atualize suas informações e utilize este novo número para entrar em contato conosco. Agradecemos a sua compreensão e continuaremos disponíveis para atender todas as suas necessidades.</p>
                    <p>Equipe BBMNET</p>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-evenly">
            <button nbButton status="danger" size="small" (click)="fecharInfo()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>