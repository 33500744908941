
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbLayoutModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MostrarDadosValidacaoPlanilhaComponent } from './mostrar-dados-validacao-planilha/mostrar-dados-validacao-planilha.component';



@NgModule({
  declarations: [ModalComponent,MostrarDadosValidacaoPlanilhaComponent],
  exports: [ModalComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule,
    NbLayoutModule,
    NbIconModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ModalModule { }
