import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { RolesGuard } from '../../shared/guards/roles.guard';
import { ControllerComponent } from '../../controller/controller.component';
import { AcessoNegadoEmailComponent } from '../errors-components/acesso-negado-email/acesso-negado-email.component';
import { AcessoNegadoComponent } from '../errors-components/acesso-negado/acesso-negado.component';
import { ListaRelatorioComponent } from './pages/lista/lista.component';
import { RelatorioComponent } from './relatorio.component';

const routes: Routes = [
  { path: '', component: RelatorioComponent,
    children: [
      { path: '', redirectTo: 'lista', pathMatch: 'full' },
      {
        path: 'lista',
        component: ListaRelatorioComponent,
      },
    ]
  }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class RelatorioRoutingModule {
}
