export enum PAGE_MENU_TITLE_ITENS {
    SALA = "Sala/Modalidades",
    EDITAIS_PROCESSOS = "Editais e Processos",
    ATAS_DOCUMENTOS = "Atas e Documentos",
    RECURSOS = "Recursos",
    RELATORIOS = "Relatórios",
    ESCLARECIMENTOS = "Esclarecimentos",
    HOME = "Home",
    IMPUGNACOES = "Impugnações",
    PESQUISAR_RELATORIO = "Pesquisar Relatório",
    APENADOS_IMPEDIDOS = "Apenados / Impedidos",
    CONTRATACAO_PNCP = "Contratações - PNCP",
    ETP = "ETP",
    PESQUISAR_PRECO = "Pesquisar Preços",
    MEUS_LOTES = "Meus Lotes",
    PARAMETRIZACAO = "Parametrização",
    LOGS_DO_SISTEMA = "Logs do Sistema",
    DOCUMENTO_HABILITACAO = "Documentos de Habilitação"
}