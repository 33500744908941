<nb-layout>
    <nb-layout-column>
        <div class="container h-100 text-center">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-9 col-xl-6 align-self-center">
                    
                    <div class="row justify-content-center mb-5">
                        <div class="col-sm-10 col-md-8">
                            <a >
                                <img src="./assets/images/bbmnet-login.png" alt="" class="img-fluid mb-5">
                            </a>
                        </div>
                    </div>

                    <h1>Não foi possivel realizar login</h1>
                    <p>Seu e-mail ainda não foi verificado. Verifique o seu e-mail e realize a ativação da sua conta.</p>

        
                    <div class="row mt-5">
                        <div class="col-12 text-center my-5">
                            <button  nbButton status="success" outline shape="semi-round" class="px-5 mx-2" (click)="voltar()">VOLTAR</button>
                            <button nbButton status="success" type="submit" shape="semi-round" class="px-5" [disabled]="disableButton" (click)="enviarEmail()" (keyup.enter)="enviarEmail()">Reenvar e-mail de confirmação</button>
                        </div>
                    </div>


                    <!-- <div class="row">
                        <div class="col-12 text-center my-3">
                            <p class="paragraph font-weight-bold"><a href="">Voltar</a></p>
                        </div>
                    </div> -->
        
        
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>