import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-guia',
  templateUrl: './guia.component.html',
  styleUrls: ['./guia.component.scss']
})
export class GuiaComponent implements OnInit {

  @ViewChild('visualizarVideo') dialogVideo!: TemplateRef<any>;
  dialogRef!: NbDialogRef<any>;

  @ViewChild('visualizarInfo') dialogInfo!: TemplateRef<any>;
  dialogInfoRef!: NbDialogRef<any>;

  constructor(private dialogService: NbDialogService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.carregarInfolicitante();
    },1000)
  }

  carregarInfolicitante(){
    this.dialogInfoRef = this.dialogService.open(this.dialogInfo, { closeOnBackdropClick: false, autoFocus: false, context: "" });
  }

  fecharInfo(){
    this.dialogInfoRef.close();
  }

  mostrarVideo(value){
    let dados;
    if(value == 1){
      dados = {
        videoDescricao: "Como inserir os documentos de habilitação",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2F1_Como%20inserir%20os%20documentos%20de%20habilita%C3%A7%C3%A3o.mp4?alt=media&token=cd20dec5-4bba-4777-a6fd-4affec234a36'
      }
    } else if (value == 2){
      dados = {
        videoDescricao: "Como cadastrar proposta",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2F2_Como%20cadastrar%20proposta.mp4?alt=media&token=99effa55-7738-4450-9100-aa1360ba48f0'
      }
    } else if (value == 21){
      dados = {
        videoDescricao: "Como cadastrar proposta por planilha",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2F2_1_Cadastro%20de%20Proposta%20por%20Planilha.mp4?alt=media&token=a4101c72-83e0-45b7-9e1f-0095cd02971e'
      }
    } else if (value == 3){
      dados = {
        videoDescricao: "Como participar da fase de Lances",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2F3_Como_participar_da_fase_de_Lances.mp4?alt=media&token=e6f8ca5b-ebe0-4011-a5f7-e4e5f8830ecf'
      }
    }
    else if (value == 4){
      dados = {
        videoDescricao: "Como redefinir o valor dos itens e inserir o arquivo da proposta final",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2F4_Como_redefinir_o_valor_dos%20itens_e_inserir_o_arquivo_da_proposta_final.mp4?alt=media&token=bd50e674-0ce5-483e-a319-4cdd0b7a168d'
      }
    }
    else if (value == 5){
      dados = {
        videoDescricao: "Como entrar com recurso",
        url: 'https://firebasestorage.googleapis.com/v0/b/bbmnet-licitacoes-prd-377317.appspot.com/o/videos_guia%2F5_Como_entrar_com_recurso.mp4?alt=media&token=4b30f386-b6aa-4386-8ac7-34d4e8205453'
      }
    }
    this.dialogRef = this.dialogService.open(this.dialogVideo, { closeOnBackdropClick: false, autoFocus: false, context: dados });
  }

  fecharVideo(){
    this.dialogRef.close();
  }

}
