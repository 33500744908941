import { Globals } from '../globals';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { CriptoLocalStorageService } from '../services/cripto-localstorage/cripto-localstorage.service';


@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {


  public rolesUser: any;
  private logged = false;

  constructor(
    protected router: Router,
    private cookieService: CriptoLocalStorageService,
    private globals: Globals,
    protected readonly keycloak: KeycloakService,) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermissoes(route);
  }


  checkPermissoes(route: ActivatedRouteSnapshot): boolean {
    let permission = false;

    if (typeof route.data['roles'] != undefined && route.data['roles'].length && this.cookieService.get('tipoParticipante') !== null) {

      const routeRoles = route.data['roles'];
      const userRoles = this.cookieService.get('tipoParticipante');

      if (this.cookieService.check("tipoParticipante")) {
        if (this.cookieService.get('tipoPessoa') === null || this.cookieService.get('uniqueIdParticipante') === null || this.cookieService.get('documentoParticipante') === null || this.cookieService.get('tipoParticipante') === null || this.cookieService.get('perfilParticipante') === null) {
          this.router.navigateByUrl('/acesso-negado');
        }




        routeRoles.forEach((routeRole: any) => {
          if (userRoles.includes(routeRole)) {
            permission = true;
          }
        });

        if (!permission) {
          this.router.navigateByUrl('/acesso-negado');
        }
      } else {
        this.router.navigateByUrl('/acesso-negado');
      }


    } else {
      this.router.navigateByUrl('/acesso-negado');
    }




    return permission;
  }

}
