<nb-card class="guia-body">
    <nb-card-header class="header-card">
        <h5 class="text-center branco size16">Guia Rápido: os 5 passos para operar o BBMNET Licitações </h5>
        <h6 class="text-center branco size14">Use apenas <b>Google Chrome</b>, <b> Opera</b>, <b>Microsoft Edge</b>, e <b>Safari</b> -
            <b class="red">Incompatível com Mozilla FireFox</b>
        </h6>
    </nb-card-header>
    <nb-card-body>
        <div class="size12">
           
            <div class="row">
                <div class="col-2">
                    <img class="img_video linkModal" (click)="mostrarVideo(1)" src="../../../assets/images/img_video.png" alt="img_video">
                </div>
                <div class="col-10">
                    <p class="text-justify ml-4 size12"><b><span class="red">1º Passo: </span>acessando o menu “documentos de habilitação”</b>. Você deverá inserir os documentos de habilitação conforme o edital do certame exigir. O BBMNET permite duas formas para cadastrar os documentos de habilitação, você pode optar pela forma que desejar: </p>
                    <p class="text-justify ml-4 size12">
                        <span class="red">i)</span> inserindo os documentos separadamente, conforme sua classificação e tipo, ou
                        <br>
                        <span class="red">ii)</span> inserindo todos os documentos em um único arquivo compactado.
                    </p>
                    <p class="text-justify ml-4 size12">
                        Após inseridos, os documentos ficarão disponíveis para serem vinculados pelo vencedor na Sala de Disputa na fase de Habilitação (exigência para os editais amparados na lei 14.133/2021).
                    </p>
                </div>
            </div>

        </div>
        <br>
        <div class="size12">
            <div class="row">
                <div class="col-2">
                    <img class="img_video linkModal" (click)="mostrarVideo(2)" src="../../../assets/images/img_video.png" alt="img_video">
                    <img class="img_video linkModal mt-1" (click)="mostrarVideo(21)" src="../../../assets/images/img_video.png" alt="img_video">
                </div>
                <div class="col-10">
                    <p class="text-justify ml-4 size12">
                        <b><span class="red">2º Passo:</span> Inserir a proposta inicial de preço (valor global por lote ou individual por item).</b> Após inserir os documentos de habilitação no Sistema, a passo seguinte é cadastrar a proposta inicial (pregão, dispensa e concorrência). O BBMNET permite duas formas de cadastrar proposta inicial de preços: 
                    </p>
                    <p class="text-justify ml-4 size12">
                        <span class="red">i) Na sala de disputa</span> (no menu azul ao lado esquerdo acima). Acesse a sala de disputa e escolha a modalidade de licitação desejada. Utilize o filtro/busca e encontre a licitação desejada na fase de “ABERTO PARA O RECEBIMENTO DE PROPOSTAS” e siga a instruções para cadastrar a proposta, ou 
                        <br>
                        <span class="red">ii) No menu Editais e Processos:</span> ir no menu Editais e processos e encontre a licitação desejada, utilizando os filtros de buscas. O licitante poderá utilizar a ferramenta para fazer um upload de planilha excel para inserir os preços e outras informações exigidas . A planilha deverá ser baixada do sistema em modelo padrão e preenchida pelo licitante. 
                    </p>
                </div>
               
            </div>

        </div>
        <br>
        <div class="size12">
            <div class="row">
                <div class="col-2">
                    <img class="img_video linkModal" (click)="mostrarVideo(3)"  src="../../../assets/images/img_video.png" alt="img_video">
                </div>
                <div class="col-10">
                    <p class="text-justify ml-4 size12">
                        <b><span class="red">3º Passo:</span> Participar da Licitação (sala de disputa)</b> no dia e hora marcados acessando a sala de disputa ao lado esquerdo canto superior da tela. Após cadastrar a proposta, participe da licitação na data e hora marcada - utilize os filtros para facilitar a busca na sala de disputa (ou o use o menu “meus lotes”)
                    </p>
                </div>
            </div>
            
        </div>
        <br>
        <div class="size12">
            <div class="row">
                <div class="col-2">
                    <img class="img_video linkModal" (click)="mostrarVideo(4)"  src="../../../assets/images/img_video.png" alt="img_video">
                </div>
                <div class="col-10">
                    <p class="text-justify ml-4 size12">
                        <b><span class="red">4º Passo:</span> Como o vencedor deverá redefinir os valores dos itens e apresentar a proposta final (após a fase de lances);</b> Após o termino da fase de lances o vencedor deverá redefinir a sua proposta com os novos valores dos itens adequados ao valor final (veja o vídeo ao lado)
                    </p>
                </div>
            </div>
            
        </div>
        <br>
        <div class="size12">
            <div class="row">
                <div class="col-2">
                    <img class="img_video linkModal" (click)="mostrarVideo(5)"  src="../../../assets/images/img_video.png" alt="img_video">
                </div>
                <div class="col-10">
                    <p class="text-justify ml-4 size12">
                        <b><span class="red">5º Passo:</span> Como entrar com os documentos do Recurso e Contra – Razões:</b> Após a fase de habilitação, o Sistema permite que os interessados possam entrar com recurso. (veja o vídeo ao lado) 
                    </p>
                </div>
            </div>
            
        </div>

    </nb-card-body>
    <nb-card-footer>
        <div>
            <p class="size12 text-center"><b>Encontre as licitações
                    desejadas
                    nos menus “Editais e Processos” (no menu ao lado) e também utilizando
                    os filtros diretamente na “sala de disputa”</b>

            </p>
        </div>
    </nb-card-footer>
</nb-card>

<ng-template #visualizarVideo let-data let-ref="dialogVideoRef">
    <nb-card>
        <nb-card-header class="header-card">
            <h5 class="text-center branco"> {{data.videoDescricao}}</h5>
        </nb-card-header>
        <nb-card-body>
            <video oncontextmenu="return false;" id="meuVideo" width="auto" height="400px" controls="controls">
                <source [src]="data.url" type="video/mp4">
            </video>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-evenly">
            <button nbButton status="danger" size="small" (click)="fecharVideo()">Fechar video</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #visualizarInfo let-data let-ref="dialogInfoRef">
    <nb-card class="info-card">
        <nb-card-header class="header-card">
            <h5 class="text-center branco">Informativo</h5>
        </nb-card-header>
        <nb-card-body>
            <div class="row row-info">
                <div class="col-12">
                    <p><b style="color: red;">ATENÇÃO,</b></p>
                    <p>Estamos atualizando nossos canais de comunicação para melhor atendê-lo. A partir de agora, <b>nosso novo telefone de contato para atendimento será: </b></p>
                    <p style="text-align: center;"><b>(11) 3181-8214.</b></p>
                    <p>Por favor, atualize suas informações e utilize este novo número para entrar em contato conosco. Agradecemos a sua compreensão e continuaremos disponíveis para atender todas as suas necessidades.</p>
                    <p>Equipe BBMNET</p>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer class="d-flex justify-content-evenly">
            <button nbButton status="danger" size="small" (click)="fecharInfo()">Fechar</button>
        </nb-card-footer>
    </nb-card>
</ng-template>