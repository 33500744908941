import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaRelatorioComponent } from './pages/lista/lista.component';
import { RelatorioRoutingModule } from './relatorio-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NbAccordionModule, NbAlertModule, NbAutocompleteModule, NbButtonModule, NbCalendarModule, NbCardModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbSpinnerModule, NbTabsetModule, NbTooltipModule } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { RelatorioComponent } from './relatorio.component';



@NgModule({
  declarations: [ListaRelatorioComponent,RelatorioComponent],
  imports: [
    CommonModule,
    RelatorioRoutingModule,
    ThemeModule,
    NbMenuModule,
    NbCardModule,
    NbAlertModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NbFormFieldModule,
    NbDatepickerModule,
    NbDialogModule,
    NbAccordionModule,
    NbTabsetModule,
    NbSelectModule,
    NbTooltipModule,
    SharedModule,
    ReactiveFormsModule,
    NbCalendarModule,
    NbAutocompleteModule
  ]
})
export class RelatorioModule { }
