import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OrgaoPromotorDadosCadastrais } from '../models/orgao-promotor-dados-cadastrais';
import { OrgaoPromotorDadosRepresentante } from '../models/orgao-promotor-dados-representante';

@Injectable({
  providedIn: 'root'
})
export class ParticipanteService {

  public naturezaOrgaoPromotor: any;
  public env = environment;
  public nomeParticipante: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) { }

  public getDadosParticipante(usuarioUniqueId:any,participanteUniqueId:any): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Licitacoes/Participante/'+ participanteUniqueId +'/Usuario/'+ usuarioUniqueId, {})
  }

}
