import { LoadingService } from './../../../shared/services/loading.service';
import { NbToastrService } from '@nebular/theme';
import { UtilsService } from './../../../shared/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-acesso-negado-email',
  templateUrl: './acesso-negado-email.component.html',
  styleUrls: ['./acesso-negado-email.component.scss']
})
export class AcessoNegadoEmailComponent implements OnInit {

  public env = environment;
  public disableButton = false;

  constructor(private keycloak: KeycloakService,
     private router: Router,
     private http: HttpClient,
     private utils: UtilsService,
     private toast: NbToastrService,
     private loading: LoadingService) { }

  ngOnInit(): void {
  }

  login(): void {

    const config = {
      redirectUri: this.env.frontRef +  '/controller'
    };

    this.keycloak.login(config);


  }

  enviarEmail() {

    this.loading.show();
    const body = {
      urlRedirecionamento: this.env.frontRef + '/controller/login'
    }

    this.sendEmail(body).subscribe( data => {
      this.toast.success('E-mail de verificação reenviado', 'Sucesso');
      this.disableButton = true;
      this.loading.hide();
    }, error => {
      this.utils.checkErrors(error);
    })
  }

  voltar() {
    this.keycloak.isLoggedIn().then( boo => {
      if(boo) {
        this.keycloak.logout(
          this.env.frontRef +  '/controller/Auth'
        )
      } 
    });
  }


  public sendEmail(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/precadastro/usuario/email-verificacao', body);
  }

}
