export enum PARTICIPANTE_USUARIO_STATUS {
    ativo = 1,
    inativo = 2
}
export enum PARTICIPANTE_STATUS {
    ativo = 4
}
export enum TIPO_PARTICIPANTE {
    bbm = "Bbm",
    filial = "Filial",
    corretora = "Corretora",
    cliente = "Cliente",
    orgaopromotor = "Órgão Promotor",
    unidadecompradora = "Unidade Compradora",
    sociedade = "Sociedade"
}

export enum TIPO_PARTICIPANTE_ID {
  corretora = 3,
}

export enum TIPO_PARTICIPANTE_MENU {
  bbm = "Bbm",
  licitante = "licitante",
  orgaopromotor = "orgaopromotor",
  sociedade = "sociedade",
  corretora = "corretora",
}

export enum PERFIL_PARTICIPANTE {
  admin = "administrador",
  gerente = "gerente",
  operador = "operador",
  diretor = "diretor",
  financeiro = "financeiro"
}
