import { TIPOS_PARTICIPANTES_USER } from "../constants/tipoParticipantes";
import { TIPO_PARTICIPANTE, TIPO_PARTICIPANTE_ID } from "../enums/participante";
import { PARTICIPANTE_STATUS, PARTICIPANTE_USUARIO_STATUS } from "../enums/participante";
import { SISTEMAS } from "../enums/sistemas";
import { Autorizacao, ParticipanteDados, UsuarioDoParticipante } from "../models/participante";


export interface Participante {
    autorizacoes: Array<ParticipanteAutorizacao>;
}
export interface ParticipanteAutorizacao {
    corretora: boolean;
    enabled: boolean;
    participante: any;
    perfil: PerfilAutorizacao;
    sistema: Sistema;
}
export interface Sistema {
    clientId: string;
    nome: string;
    uniqueId: string;
}

export interface PerfilAutorizacao {
    descricao: string;
    nome: string;
    uniqueId: string;
}

export class ParticipanteAtivo {
    public retornaparticipanteAtivoVerificacao(participantes: any) {
        let filter = participantes.participantes.filter((participanteDados: ParticipanteDados) => {
            return (participanteDados.participante.tipoParticipante.name != TIPO_PARTICIPANTE.cliente || (participanteDados.participante.tipoParticipante.name == TIPO_PARTICIPANTE.cliente && participanteDados.assinaturaStatus.id == 1)) && participanteDados.participanteUsuarioStatus.id == PARTICIPANTE_USUARIO_STATUS.ativo && this.verificaPermissaoLicitacao(participanteDados.usuario, participanteDados.participante.uniqueId) && participanteDados.participante.participanteStatus.id == PARTICIPANTE_STATUS.ativo && !this.verificaIsParticipanteOfCorretora(participantes, participanteDados);
        });
        return filter;
    }
    verificaPermissaoLicitacao(participante: UsuarioDoParticipante, participanteId: string): boolean {

        let permite = false;
        participante.autorizacoes.filter((auth: Autorizacao) => {
            if (auth.sistema.uniqueId == SISTEMAS.BBMNETLicitacoes && auth.participante.uniqueId == participanteId && auth.enabled == true) {
                permite = true
            }
        })
        return permite;
    }

    verificaIsParticipanteOfCorretora(participantes: any, participanteDados: ParticipanteDados): boolean {
        let participantesFiltered = participantes.autorizacoes.filter((participante: ParticipanteAutorizacao) => {  
            return participante?.participante.uniqueId == participanteDados?.participante?.uniqueId && participante?.sistema?.uniqueId == SISTEMAS.BBMNETLicitacoes
        });
        if(participantesFiltered.length == 0){
            return true;
        }
        if(participantesFiltered[0].corretora == false){
            return false;
        }
        return true;
    }
}