import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-acesso-negado',
  templateUrl: './acesso-negado.component.html',
  styleUrls: ['./acesso-negado.component.scss']
})
export class AcessoNegadoComponent implements OnInit {
  public env = environment;

  constructor(private keycloak: KeycloakService, private router: Router) { }

  ngOnInit(): void {
    // this.keycloak.isLoggedIn().then(boo => {
    //   if (boo) {
    //     this.keycloak.logout();
    //   } 
    // });
  }

  login(): void {

    // const config = {
    //   redirectUri: this.env.frontRef +  '/controller/logout'
    // };

    // this.keycloak.login(config);
    this.keycloak.isLoggedIn().then( boo => {
      if(boo) {
        this.keycloak.logout(
          this.env.frontRef +  '/controller/Auth'
        )
      } 
    });
   


  }

  voltar() {
    this.router.navigateByUrl('/');
  }

}
