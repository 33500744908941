import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACTION_BUTTON } from '../../../enums/action_button.enum';

@Component({
  selector: 'dados-lotes-proposta-planilha',
  templateUrl: './mostrar-dados-validacao-planilha.component.html',
  styleUrls: ['./mostrar-dados-validacao-planilha.component.scss']
})
export class MostrarDadosValidacaoPlanilhaComponent implements OnInit {
  @Input('lotes') lotes: any[] = [];
  @Output() closeModal = new EventEmitter<any>();
  constructor() { }
  ngOnInit() { 
    // console.log(this.lotes)
  }

  close(){
    this.closeModal.emit(ACTION_BUTTON.closemodal)
  }

  valorDecimalQuantidade(valor: number) {
    return Intl.NumberFormat().format(
      valor
    )
  }
}


