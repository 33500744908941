import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loading = false;
  public message = '';

  constructor() { }

  public show(message?: string) {
    
    if(message) {
      this.message = message;
    } else {
      this.message = '';
    }

    this.loading = true;
  }

  public hide() {
    this.loading = false;
    this.message = '';
  }

  public getLoading(): boolean {
    return this.loading;
  }

  public getMessage(): string {
    return this.message;
  }
}
