import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bbm-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input('totalPages')
  totalPages: string | any[] = [];
  _page!: number;

  @Input() set page(value: number) {
    this._page = value;
  };
  @Output() pageEvent = new EventEmitter();

  private totalPageLimited;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    // if(changes.page) {
    //   this.setPage(changes.page.currentValue);
    // }
  }

  setPage(page: number) {
    this._page = page;
    this.pageEvent.emit(this._page);
  }
  nextPage() {
    if (this._page + 1 < this.totalPages.length) {
      this._page = this._page + 1;
      this.pageEvent.emit(this._page);
    }
  }
  prevPage() {
    if (this._page - 1 >= 0) {
      this._page = this._page - 1;
      this.pageEvent.emit(this._page);
    }
  }

  ngOnInit() { }

  alterarNumeroLimitePage(){

  }

  verificaExibePagina(totalPages,_page,i){
    if(
      totalPages.length<10 || 
      _page < 5 && i < 9 || 
      (i >= _page - 4 && i <= _page + 4) ||
      (i > totalPages.length - 10 && _page > totalPages.length - 6)

    ){
      return true
    }
    return false
  }

}
