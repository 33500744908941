import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header-sala-disputa',
  styleUrls: ['./header-sala-disputa.component.scss'],
  templateUrl: './header-sala-disputa.component.html',
})
export class HeaderSalaDisputaComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  user: any;
  userPictureOnly: boolean = false;
  public tokenInfo: any;
  public env = environment;

  public userMenu = [ { title: 'Meus dados' }, { title: 'Sair' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private layoutService: LayoutService,
              protected router: Router,
              private keycloak: KeycloakService) {
  }

  ngOnInit() {

    this.tokenInfo = this.keycloak.getKeycloakInstance().idTokenParsed;

    this.user = {
      name: this.tokenInfo.nome.length>25?this.tokenInfo.nome.toString().substring(0,25) + "...":this.tokenInfo.nome,
      picture: 'assets/images/user.svg'
    };

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === "Sair") {
        this.logout();
      }
      if (event.item.title === "Meus dados") {
        this.router.navigateByUrl("/perfil/meusdados");
      }
    });
  
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    localStorage.removeItem("auth_app_token");
    this.keycloak.logout(this.env.frontRef + '/auth/login');
    this.router.navigateByUrl("/auth/login");
  }

}
