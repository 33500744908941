import { Input } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { environment } from '../environments/environment';
import { StatusService } from './shared/services/status.service';
import { PERFIL_PARTICIPANTE, TIPO_PARTICIPANTE_MENU } from './shared/enums/participante';
import { MODALIDADES } from './shared/enums/modalidades';
import { PAGE_MENU_TITLE_ITENS } from './shared/enums/page-menu-title-itens.enum';
const packageJson = require('./../../package.json');

const ListChildrenMenuSalaDisputa: NbMenuItem[] = [
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.PREGAO.Name,
    home: true,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.CONCORRENCIA.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',

  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.CREDENCIAMENTO.Name,
    url: environment.frontRef + "controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.DISPENSA.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',

  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.DIALOGO_COMPETITIVO.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.LEILAO.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  }
]
const ListChildrenMenuParametrizacao: NbMenuItem[] = [
  {
    icon: 'chevron-right-outline',
    title: 'LEIS',
    home: true,
    link: '/leis/lista',
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: 'PLANOS',
    home: true,
    link: '/planos/change-plano-avulso',
    pathMatch: 'prefix',
  }
]

export const MENU_RELATORIO:NbMenuItem = {
  title: PAGE_MENU_TITLE_ITENS.RELATORIOS,
  icon: 'bar-chart-2-outline',
  url: environment.frontRefSalaDisputa + "/controller",
  target: '_blank'

}

export const MENU_HOME:NbMenuItem = {
  title: PAGE_MENU_TITLE_ITENS.HOME,
  icon: 'home-outline',
  link: '/home/guia',
}

export const MENU_HOME_PREGOEIRO:NbMenuItem = {
  title: PAGE_MENU_TITLE_ITENS.HOME,
  icon: 'home-outline',
  link: '/home/guia-pregoeiro',
}

export const VERSION:NbMenuItem = {
  title: `Version: ${packageJson.version}`,
}

const ListChildrenMenuMeusLotes: NbMenuItem[] = [
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.PREGAO.Name,
    home: true,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.CONCORRENCIA.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',

  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.CREDENCIAMENTO.Name,
    url: environment.frontRef + "controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.DISPENSA.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.DIALOGO_COMPETITIVO.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  },
  {
    icon: 'chevron-right-outline',
    title: MODALIDADES.LEILAO.Name,
    url: environment.frontRefSalaDisputa + "/controller",
    pathMatch: 'prefix',
  }
]

export class MenuItensGeral {

  constructor(private statusService: StatusService) { }

  public get MENU_ITEMS(): NbMenuItem[]  {

    let tipoParticipante = this.statusService.getTipoParticipante()
    let perfilParticipante = this.statusService.getPerfilParticipante()
    if(tipoParticipante === TIPO_PARTICIPANTE_MENU.orgaopromotor){
      if(this.statusService.participanteVisualizacaoEditais()) {
        return this.MENU_ITEMS_LICITANTE
      } else {
        return this.MENU_ITEMS_ORGAO
      }
    } else if (tipoParticipante === TIPO_PARTICIPANTE_MENU.licitante){
      return this.MENU_ITEMS_LICITANTE
    } else if (tipoParticipante === TIPO_PARTICIPANTE_MENU.corretora){
      if(perfilParticipante === PERFIL_PARTICIPANTE.admin || perfilParticipante === PERFIL_PARTICIPANTE.financeiro) {
        return this.MENU_ITEMS_CORRETORA
      }
      return this.MENU_ITEMS_CORRETORA.filter((item) => item.title !== "Relatórios")
    } else if (tipoParticipante === TIPO_PARTICIPANTE_MENU.bbm) {
      if(perfilParticipante === PERFIL_PARTICIPANTE.diretor || perfilParticipante === PERFIL_PARTICIPANTE.financeiro){
        return this.MENU_ITEMS_BBM
      }
      return this.MENU_ITEMS_BBM.filter((item) => item.title !== "Relatórios" && item.title !== "Parametrização")
    } else if (tipoParticipante === TIPO_PARTICIPANTE_MENU.sociedade) {
      return this.MENU_ITEMS_SOCIEDADE
    } else {
      return this.MENU_ITEMS_SOCIEDADE
    }
  }

  public MENU_ITEMS_ORGAO: NbMenuItem[] = [
    MENU_HOME_PREGOEIRO,
    {
      title: PAGE_MENU_TITLE_ITENS.SALA,
      icon: 'copy-outline',
      home: true,
      children: ListChildrenMenuSalaDisputa
      // group: true,
    },
    {
      title: PAGE_MENU_TITLE_ITENS.EDITAIS_PROCESSOS,
      icon: 'shopping-cart-outline',
      link: '/orgaopromotor/editais',
      home: true,
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS,
      icon: 'file-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.RECURSOS,
      icon: 'archive-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    // MENU_RELATORIO,
    {
      title: PAGE_MENU_TITLE_ITENS.ESCLARECIMENTOS,
      icon: 'sun-outline',
      link: '/esclarecimentos',
    },

    {
      title: PAGE_MENU_TITLE_ITENS.IMPUGNACOES,
      icon: 'alert-circle-outline',
      link: '/impugnacoes',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.APENADOS_IMPEDIDOS,
      icon: 'stop-circle-outline',
      // link: '/apenados-impedidos',
      children: [
        {
          icon: 'chevron-right-outline',
          title: 'Pesquisar',
          home: true,
          url: environment.frontRefSalaDisputa + "/controller",
          pathMatch: 'prefix',
        },
        {
          icon: 'chevron-right-outline',
          title: 'Incluir',
          url: environment.frontRefSalaDisputa + "/controller",
          // link: '/main/participante/pessoa-juridica',
          pathMatch: 'prefix',

        }
      ]
    },
    {
      title: PAGE_MENU_TITLE_ITENS.CONTRATACAO_PNCP,
      icon: 'file-text-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ETP,
      icon: 'file-text-outline',
      link: '/estudo-tecnico-preliminar',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.PESQUISAR_PRECO,
      icon: 'file-outline',
      link: "/pesquisa-preco",
    },
    // VERSION
  ];

  public MENU_ITEMS_LICITANTE: NbMenuItem[] = [
    MENU_HOME,
    {
      title: PAGE_MENU_TITLE_ITENS.SALA,
      icon: 'copy-outline',
      children: ListChildrenMenuSalaDisputa
    },
    {
      title: PAGE_MENU_TITLE_ITENS.MEUS_LOTES,
      icon: 'layers-outline',
      link: '/pages/iot-dashboard',
      children: ListChildrenMenuMeusLotes
    },
    {
      title: PAGE_MENU_TITLE_ITENS.EDITAIS_PROCESSOS,
      icon: 'shopping-cart-outline',
      url: '/visaoeditais/editais',
      home: true,
    },
    {
      title: PAGE_MENU_TITLE_ITENS.DOCUMENTO_HABILITACAO,
      icon: 'file-text-outline',
      link: '/licitante/documentos',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS,
      icon: 'file-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.RECURSOS,
      icon: 'archive-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    // MENU_RELATORIO,
    {
      title: PAGE_MENU_TITLE_ITENS.ESCLARECIMENTOS,
      icon: 'sun-outline',
      link: '/esclarecimentos',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.IMPUGNACOES,
      icon: 'alert-circle-outline',
      link: '/impugnacoes',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.APENADOS_IMPEDIDOS,
      icon: 'stop-circle-outline',
      // link: '/pages/iot-dashboard',
      children: [
        {
          icon: 'chevron-right-outline',
          title: 'Pesquisar',
          home: true,
          url: environment.frontRefSalaDisputa + "/controller",
          pathMatch: 'prefix',
        }
      ]
    },
    // VERSION
  ];

  public MENU_ITEMS_CORRETORA: NbMenuItem[] = [
    {
      title: PAGE_MENU_TITLE_ITENS.SALA,
      icon: 'copy-outline',
      children: ListChildrenMenuSalaDisputa
    },
    {
      title: PAGE_MENU_TITLE_ITENS.MEUS_LOTES,
      icon: 'layers-outline',
      link: '/pages/iot-dashboard',
      children: ListChildrenMenuMeusLotes
    },
    {
      title: PAGE_MENU_TITLE_ITENS.EDITAIS_PROCESSOS,
      icon: 'shopping-cart-outline',
      url: '/visaoeditais/editais',
      home: true,
    },
    {
      title: PAGE_MENU_TITLE_ITENS.DOCUMENTO_HABILITACAO,
      icon: 'file-text-outline',
      link: '/licitante/documentos',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS,
      icon: 'file-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.RECURSOS,
      icon: 'archive-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    MENU_RELATORIO,
    {
      title: PAGE_MENU_TITLE_ITENS.ESCLARECIMENTOS,
      icon: 'sun-outline',
      link: '/esclarecimentos',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.IMPUGNACOES,
      icon: 'alert-circle-outline',
      link: '/impugnacoes',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.APENADOS_IMPEDIDOS,
      icon: 'stop-circle-outline',
      // link: '/pages/iot-dashboard',
      children: [
        {
          icon: 'chevron-right-outline',
          title: 'Pesquisar',
          home: true,
          url: environment.frontRefSalaDisputa + "/controller",
          pathMatch: 'prefix',
        }
      ]
    },
    // VERSION
  ];

  public MENU_ITEMS_BBM: NbMenuItem[] = [
    {
      title: PAGE_MENU_TITLE_ITENS.SALA,
      icon: 'copy-outline',
      children: ListChildrenMenuSalaDisputa
    },
    {
      title: PAGE_MENU_TITLE_ITENS.EDITAIS_PROCESSOS,
      icon: 'shopping-cart-outline',
      url: '/visaoeditais/editais',
      home: true,
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS,
      icon: 'file-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.RECURSOS,
      icon: 'archive-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    MENU_RELATORIO,
    {
      title: PAGE_MENU_TITLE_ITENS.ESCLARECIMENTOS,
      icon: 'sun-outline',
      link: '/esclarecimentos',
    },

    {
      title: PAGE_MENU_TITLE_ITENS.IMPUGNACOES,
      icon: 'alert-circle-outline',
      link: '/impugnacoes',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.APENADOS_IMPEDIDOS,
      icon: 'stop-circle-outline',
      children: [
        {
          icon: 'chevron-right-outline',
          title: 'Pesquisar',
          home: true,
          url: environment.frontRefSalaDisputa + "/controller",
          pathMatch: 'prefix',
        }
      ]
    },
    {
      title: PAGE_MENU_TITLE_ITENS.LOGS_DO_SISTEMA,
      icon: 'file-text-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.PARAMETRIZACAO,
      icon: 'file-text-outline',
      children: ListChildrenMenuParametrizacao
    },
    {
      title: PAGE_MENU_TITLE_ITENS.PESQUISAR_PRECO,
      icon: 'file-outline',
      link: "/pesquisa-preco",
    },
    // VERSION
  ];


  public MENU_ITEMS_SOCIEDADE: NbMenuItem[] = [
    {
      title: PAGE_MENU_TITLE_ITENS.SALA,
      icon: 'copy-outline',
      home: true,
      children: ListChildrenMenuSalaDisputa
    },
    {
      title: PAGE_MENU_TITLE_ITENS.EDITAIS_PROCESSOS,
      icon: 'shopping-cart-outline',
      link: '/visaoeditais/editais',
      home: true,
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS,
      icon: 'file-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.RECURSOS,
      icon: 'archive-outline',
      url: environment.frontRefSalaDisputa + "/controller",
    },
    {
      title: PAGE_MENU_TITLE_ITENS.ESCLARECIMENTOS,
      icon: 'sun-outline',
      link: '/esclarecimentos',
    },
    {
      title: PAGE_MENU_TITLE_ITENS.IMPUGNACOES,
      icon: 'alert-circle-outline',
      link: '/impugnacoes',
    },
    // VERSION
  ];
}
