import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './shared/guards/auth.guard';
import { RolesGuard } from './shared/guards/roles.guard';
import { ControllerComponent } from './controller/controller.component';
import { AcessoNegadoEmailComponent } from './components/errors-components/acesso-negado-email/acesso-negado-email.component';
import { AcessoNegadoComponent } from './components/errors-components/acesso-negado/acesso-negado.component';
import { UsuarioComponent } from './shared/components/usuario/usuario.component';
import { HomeComponent } from './components/home/home.component';
import { JoinsyComponent } from './components/joinsy/joinsy.component';


export const routes: Routes = [
  { path: '', redirectTo: 'controller', pathMatch: 'full' },
  {
    path: 'home', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['licitante','orgaopromotor', 'sociedade'] }
  },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.NgxAuthModule) },
  {
    path: 'licitante', loadChildren: () => import('./components/licitante/licitante.module').then(m => m.LicitanteModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['licitante', 'Bbm', 'sociedade'] }
  },
  {
    path: 'orgaopromotor', loadChildren: () => import('./components/orgaopromotor/orgaopromotor.module').then(m => m.OrgaopromotorModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['orgaopromotor', 'unidadecompradora', 'Bbm'] }
  },
  {
    path: 'visaoeditais', loadChildren: () => import('./components/orgaopromotor-visao/orgaopromotor.module').then(m => m.OrgaopromotorVisaoModule),
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['licitante', 'sociedade', 'Bbm', 'corretora'] }
  },
  // { path: 'participantes', loadChildren: () => import('./shared/components/usuario/usuario.module').then(m => m.UsuarioModule),canActivate: [AuthGuard]},
  { path: 'controller', component: ControllerComponent },
  { path: 'controller/:id', component: ControllerComponent },
  { path: 'acesso-negado', component: AcessoNegadoComponent },
  { path: 'acesso-negado-email', component: AcessoNegadoEmailComponent },
  { path: 'controller/:ref', component: ControllerComponent, data: { redirectLogin: true } },
  { path: 'esclarecimentos', loadChildren: () => import('./components/esclarecimentos/esclarecimentos.module').then(m => m.EsclarecimentosModule) },
  { path: 'impugnacoes', loadChildren: () => import('./components/impugnacoes/impugnacoes.module').then(m => m.ImpugnacoesModule) },
  { path: 'relatorios', loadChildren: () => import('./components/relatorio/relatorio.module').then(m => m.RelatorioModule), canActivate: [AuthGuard, RolesGuard], data: { roles: ['corretora', 'Bbm', 'orgaopromotor'] } },
  { path: 'estudo-tecnico-preliminar', loadChildren: () => import('./components/estudo-tecnico-preliminar/estudo-tecnico-preliminar.module').then(m => m.EstudoTecnicoPreliminarModule), canActivate: [AuthGuard, RolesGuard], data: { roles: ['orgaopromotor'] } },
  { path: 'leis', loadChildren: () => import('./components/crud-leis/crud-leis.module').then(m => m.CrudLeisModule), canActivate: [AuthGuard, RolesGuard], data: { roles: ['Bbm'] } },
  { path: 'pesquisa-preco', loadChildren: () => import('./components/joinsy/joinsy.module').then(m => m.JoinsyModule), canActivate: [AuthGuard, RolesGuard], data: { roles: ['Bbm', 'orgaopromotor'] }},
  { path: 'cadastro', loadChildren: () => import('./components/cadastro-proposta/cadastro-proposta.module').then(m => m.CadastroPropostaModule), canActivate: [AuthGuard, RolesGuard], data: { roles: ['licitante'] } },
  { path: 'planos', loadChildren: () => import('./components/gestao-planos/gestao-planos.module').then(m => m.GestaoPlanosModule), canActivate: [AuthGuard, RolesGuard], data: { roles: ['Bbm'] } },
  { path: '**', redirectTo: 'controller' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
