import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';
import { OrgaoPromotorService } from '../../../shared/services/orgao-promotor.service';
import { LoadingService } from '../../../shared/services/loading.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsertValidateCredencials } from '../../../shared/services/insert-validate-credencials';
import { ParticipanteDados, UsuarioDoParticipante } from '../../../shared/models/participante';
import { PARTICIPANTE_STATUS, PARTICIPANTE_USUARIO_STATUS } from '../../../shared/enums/participante';
import { SISTEMAS } from '../../../shared/enums/sistemas';
import { ParticipanteAtivo } from '../../../shared/utils/filterParticipanteAtivo';
import { TIPOS_PARTICIPANTES_USER } from '../../../shared/constants/tipoParticipantes';
import { CriptoLocalStorageService } from '../../../shared/services/cripto-localstorage/cripto-localstorage.service';
import { Store } from '@ngrx/store';
import { SetListaParticipante } from '../../../shared/store/listaParticipante.ngrx';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  user: any;
  userPictureOnly: boolean = false;
  public tokenInfo: any;
  public env = environment;
  public listParticipantes: Array<any>;
  public autorizacao: Array<any>;
  public form!: FormGroup;
  public participanteId;
  public participanteAtivo = new ParticipanteAtivo();

  public userMenu = [{ title: 'Meus dados' }, { title: 'Sair' }];

  public isSociedade: boolean = null;
  public perfilParticipante;
  public tipoParticipante;

  public listaParticipantes$: Observable<any>;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private orgaoPromotorService: OrgaoPromotorService,
    private layoutService: LayoutService,
    protected router: Router,
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private cookieService: CriptoLocalStorageService,
    private keycloak: KeycloakService,
    private insertValidateCredencial: InsertValidateCredencials,
    private store: Store<any>) {
  }

  private createForm() {
    if (this.cookieService.get('uniqueIdParticipante') !== null) {
      this.perfilParticipante = this.cookieService.get('perfilParticipante');
      this.tipoParticipante = this.cookieService.get('tipoParticipante');
      if(this.cookieService.get('uniqueIdParticipante') == this.cookieService.get('uniqueIdUsuario')){
        this.participanteId = this.cookieService.get('tipoParticipante');
      }else{
        this.participanteId = this.cookieService.get('uniqueIdParticipante');
      }

      // if(this.tipoParticipante == TIPOS_PARTICIPANTES_USER.SOCIEDADE){
      //   this.participanteId = TIPOS_PARTICIPANTES_USER.SOCIEDADE;
      // }
    

      let nameUser = this.tokenInfo.given_name + " " + this.tokenInfo.family_name;
      this.user = {
        nameComplete: nameUser,
        name: nameUser?.toString().length > 25 ? nameUser.toString().substring(0, 25) + "..." : nameUser,
        picture: 'assets/images/user.svg',
        profile: this.perfilParticipante,
        participanteId: this.participanteId
      };
    }
    this.form = this.formBuilder.group({
      participanteId: [this.participanteId, [Validators.required]]
    })

    this.getparticipantes();

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === "Sair") {
        this.logout();
      }
      if (event.item.title === "Meus dados") {
        this.router.navigateByUrl("/perfil/meusdados");
      }
    });
  }


  ngOnInit() {
    this.tokenInfo = this.keycloak.getKeycloakInstance().idTokenParsed;
    this.createForm()
    
  }


  getparticipantes() {
    this.listaParticipantes$ = this.store.select('listaParticipanteReducer')
    this.listaParticipantes$.subscribe((res) => {
      if(res.value){
        let listaParticipantes = res.value;
        this.listParticipantes = this.participanteAtivo.retornaparticipanteAtivoVerificacao(listaParticipantes);
      }else{
        this.getparticipantesAPI();
      }
    })
  }

  getparticipantesAPI() {
    this.orgaoPromotorService.getParticipantesUsuario(this.tokenInfo.sub).subscribe((res) => {
      this.store.dispatch(SetListaParticipante({payload: res}));
      this.listParticipantes = this.participanteAtivo.retornaparticipanteAtivoVerificacao(res);
    })
  }



  getUserRoles(event) {
    if (event == 'sociedade') {
      this.isSociedade = true;
    } else {
      let autorizacao = this.listParticipantes.filter((participante: ParticipanteDados) => {
        return participante.participante.uniqueId == this.form.controls.participanteId.value;
      });

      var userRoles:string[] = this.insertValidateCredencial.getUserRoles(autorizacao[0].usuario.autorizacoes,this.form.value.participanteId);

      if(userRoles.includes('orgaopromotor') || userRoles.includes('unidadecompradora')  || userRoles.includes('Bbm') || userRoles.includes('licitante') || userRoles.includes('corretora')) {
        this.loading.hide();
        this.router.navigateByUrl('/controller');
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  verificarDisableComboBoxParticipante() {
    let tipoParticipante = this.cookieService.get('tipoParticipante');
    if (tipoParticipante == TIPOS_PARTICIPANTES_USER.SOCIEDADE) {
      return true;
    }
    return false;
  }
  logout() {
    this.cookieService.deleteAll("/");
    localStorage.removeItem("auth_app_token");
    this.keycloak.logout(this.env.frontRef + '/auth/login');
  }

  getDocumentoToolTip(documento: string) {
    let documentoParsed = parseInt(documento);
    if(documentoParsed.toString() == "NaN"){
      return documento;
    }
    if (documento.length > 12) {
      return documento.substring(0, 2) + "." + documento.substring(2, 5) + "." + documento.substring(5, 8) + "/" + documento.substring(8, 12) + "-" + documento.substring(12, 14)
    } else {
      return documento.substring(0, 3) + "." + documento.substring(3, 6) + "." + documento.substring(6, 9) + "-" + documento.substring(9, 11)
    }
  }


}
