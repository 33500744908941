import { NbMenuItem } from "@nebular/theme";
import { environment } from "../../../environments/environment";
import { MODALIDADES } from '../enums/modalidades';
import { CriptoLocalStorageService } from "./cripto-localstorage/cripto-localstorage.service";
import { PAGE_MENU_TITLE_ITENS } from "../enums/page-menu-title-itens.enum";
export class ChangePath {
    constructor(private cookieService: CriptoLocalStorageService) {

    }

    public changePath(menu: NbMenuItem[]) {
        let pathBase = environment.frontRefSalaDisputa + "/controller";

        let uniqueIdParticipante = this.cookieService.get('uniqueIdParticipante');
        menu.filter((data) => {
            if (uniqueIdParticipante) {
                if (data.title == PAGE_MENU_TITLE_ITENS.SALA) {
                    data.children.forEach((res) => {
                        let idModalidade = this.returnIdModalidade(res);
                        res.url = pathBase + "/" + uniqueIdParticipante + "?" + `modalidade=${idModalidade}`
                    })
                }
                if (data.title == PAGE_MENU_TITLE_ITENS.MEUS_LOTES) {
                    data.children.forEach((res) => {
                        let idModalidade = this.returnIdModalidade(res);
                        res.url = pathBase + "/" + uniqueIdParticipante + "?" + `modalidade=${idModalidade}` + "&" + `meuslotes=${true}`
                    })
                }
                if (data.title == PAGE_MENU_TITLE_ITENS.LOGS_DO_SISTEMA) {
                    data.url = data.url + "/" + uniqueIdParticipante + "?" + "route=logs"
                }
                if (data.title == PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS) {
                    data.url = data.url + "/" + uniqueIdParticipante + "?" + "route=relatorios"
                }
                if (data.title == PAGE_MENU_TITLE_ITENS.RECURSOS) {
                    data.url = data.url + "/" + uniqueIdParticipante + "?" + "route=recursos"
                }
                if (data.title == PAGE_MENU_TITLE_ITENS.APENADOS_IMPEDIDOS) {
                    data.children.forEach((res) => {
                        if (res.title == "Pesquisar") {
                            res.url = res.url + "/" + uniqueIdParticipante + "?" + "route=pesquisar_apenadoimpedido"
                        }
                        if (res.title == "Incluir") {
                            res.url = res.url + "/" + uniqueIdParticipante + "?" + "route=incluir_apenadoimpedido"
                        }
                    })
                }
                if (data.title == PAGE_MENU_TITLE_ITENS.CONTRATACAO_PNCP) {
                    data.url = data.url + "/" + uniqueIdParticipante + "?" + "route=contratacao"
                }

                if (data.title == PAGE_MENU_TITLE_ITENS.RELATORIOS) {
                    data.url = data.url + "/" + uniqueIdParticipante + "?" + "route=relatorios-adm"
                }
            }

        })

    }

    returnIdModalidade(res): string {
        if (res.title == MODALIDADES.PREGAO.Name) {
            return MODALIDADES.PREGAO.Id;
        } else if (res.title == MODALIDADES.DISPENSA.Name) {
            return MODALIDADES.DISPENSA.Id;
        } else if (res.title == MODALIDADES.CONCORRENCIA.Name) {
            return MODALIDADES.CONCORRENCIA.Id;
        } else if (res.title == MODALIDADES.DIALOGO_COMPETITIVO.Name) {
            return MODALIDADES.DIALOGO_COMPETITIVO.Id;
        } else if (res.title == MODALIDADES.LEILAO.Name) {
            return MODALIDADES.LEILAO.Id;
        }else if (res.title == MODALIDADES.CREDENCIAMENTO.Name) {
            return MODALIDADES.CREDENCIAMENTO.Id;
        } else {
            return MODALIDADES.PREGAO.Id;
        }
    }

    changePathOnDestroy(menu: NbMenuItem[]) {
        let uniqueIdParticipante = this.cookieService.get('uniqueIdParticipante');
        menu.filter(data => {
            if (data.title == PAGE_MENU_TITLE_ITENS.SALA) {
                data.children.forEach((res) => {
                    res.url = environment.frontRefSalaDisputa + "/controller"
                })
            }
            if (data.title == PAGE_MENU_TITLE_ITENS.LOGS_DO_SISTEMA) {
                data.url = environment.frontRefSalaDisputa + "/controller"
            }
            if (data.title == PAGE_MENU_TITLE_ITENS.ATAS_DOCUMENTOS) {
                data.url = environment.frontRefSalaDisputa + "/controller"
            }
            if (data.title == PAGE_MENU_TITLE_ITENS.RECURSOS) {
                data.url = data.url + "/" + uniqueIdParticipante + "?" + "route=recursos"
            }
            if (data.title == PAGE_MENU_TITLE_ITENS.APENADOS_IMPEDIDOS) {
                data.children.forEach((res) => {
                    if (res.title == "Pesquisar") {
                        res.url = environment.frontRefSalaDisputa + "/controller"
                    }
                    if (res.title == "Incluir") {
                        res.url = environment.frontRefSalaDisputa + "/controller"
                    }
                })
            }
        })
    }
}