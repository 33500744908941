import { StatusService } from './../../shared/services/status.service';
import { Component, OnInit } from '@angular/core';
import { CrudFirebase } from '../../shared/services/crud.firestore';
import { MenuItensGeral } from '../../pages-menu';


@Component({
  selector: 'ngx-relatorio',
  templateUrl: './relatorio.component.html',
  styleUrls: ['./relatorio.component.scss']
})
export class RelatorioComponent implements OnInit {

  menu = new MenuItensGeral(this.statusService).MENU_ITEMS;



  constructor(private statusService: StatusService) { }

  ngOnInit(): void {

  }



}
