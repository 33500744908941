import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { environment } from "../../../environments/environment";
import { PARTICIPANTE_STATUS, TIPO_PARTICIPANTE, TIPO_PARTICIPANTE_ID } from "../enums/participante";
import { SISTEMAS } from "../enums/sistemas";
import { CriptoLocalStorageService } from "./cripto-localstorage/cripto-localstorage.service";


@Injectable({
  providedIn: 'root'
})

export class InsertValidateCredencials {
  public env = environment;
  public tokenInfo: any;
  constructor(
    private cookieService: CriptoLocalStorageService,
    protected router: Router,
    private keycloak: KeycloakService,
  ) { }

  deleteCookies() {
    this.cookieService.deleteAll("/");
  }

  public getUserRoles(autorizacoes: any,participanteUniqueId?: string): string[] {
    try {
      var retorno: string[] = []
      if(autorizacoes?.length == 0 || autorizacoes?.autorizacoes?.length == 0){
        this.deleteCookies();
        this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
        retorno.push("sociedade");
        this.setUserData("sociedade",autorizacoes?.uniqueId);
        return retorno
      } 
      if(autorizacoes?.autorizacoes?.length > 1){
        let autorizacao = autorizacoes?.autorizacoes?.filter((p: any) => {
          return p.sistema.uniqueId == SISTEMAS.BBMNETLicitacoes && p.enabled;
        })
        if(autorizacao.length<1){
          this.deleteCookies();
          this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
          retorno.push("sociedade");
          this.setUserData("sociedade",autorizacoes?.uniqueId);
          return retorno
        }
      }
      var filtro;
  
      if(participanteUniqueId){
        this.deleteCookies() 
        filtro = autorizacoes?.filter((p: any) => {
          return p?.participante?.uniqueId == participanteUniqueId && p?.sistema?.uniqueId == SISTEMAS.BBMNETLicitacoes && p?.corretora == false;
        })

      }else{
        filtro = autorizacoes?.filter((p: any) => {
          return p?.sistema?.uniqueId == SISTEMAS.BBMNETLicitacoes && p?.participante?.participanteStatus?.id == PARTICIPANTE_STATUS.ativo && p?.corretora == false;
        })
        if (filtro.length < 1) {
          //desloga devido a nenhum dos participantes está ativo, e o autorizacoes não ter a propriedade de uniqueId
          this.keycloak.logout();
        }
      }
  
      let autorizacao: any = filtro[0]

  
      if (autorizacao?.sistema?.uniqueId == SISTEMAS.BBMNETLicitacoes) {
        if (autorizacao?.participante?.tipoParticipante?.name == TIPO_PARTICIPANTE.orgaopromotor) {
          this.cookieService.set('tipoParticipante', "orgaopromotor", { path: "/" });
          retorno.push("orgaopromotor");
          this.setUserData(autorizacao);
        } else if (autorizacao?.participante?.tipoParticipante?.name == TIPO_PARTICIPANTE.unidadecompradora) {
          this.cookieService.set('tipoParticipante', "unidadecompradora", { path: "/" });
          retorno.push("unidadecompradora");
          this.setUserData(autorizacao);
        } else if (autorizacao?.participante?.tipoParticipante?.name == TIPO_PARTICIPANTE.cliente) {
          this.cookieService.set('tipoParticipante', "licitante", { path: "/" });
          retorno.push("licitante");
          this.setUserData(autorizacao);
        } else if (autorizacao?.participante?.tipoParticipante?.name == TIPO_PARTICIPANTE.bbm) {
          this.cookieService.set('tipoParticipante', "Bbm", { path: "/" });
          retorno.push("Bbm");
          this.setUserData(autorizacao);
        } else if (autorizacao?.participante?.tipoParticipante?.name == TIPO_PARTICIPANTE.corretora) {
          this.deleteCookies();
          this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
          retorno.push("corretora");
          this.setUserData("corretora",autorizacao);
          return retorno
        }
      }else{
        this.deleteCookies();
        this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
        retorno.push("sociedade");
        this.setUserData("sociedade",autorizacoes?.uniqueId);
        return retorno
      }
      return retorno
    } catch (error) {
      this.keycloak.logout();
    }
  }

  setUserData(autorizacao,userData?:any){
    if(autorizacao.corretora){
      this.cookieService.set('IsUserCorretora', autorizacao.corretora.toString(), { path: "/" });
    }else{
      this.cookieService.set('IsUserCorretora', 'false', { path: "/" });
    }
    this.tokenInfo = this.keycloak.getKeycloakInstance().idTokenParsed;
    this.cookieService.set('uniqueIdUsuario', this.tokenInfo.sub, { path: "/" });
    if(autorizacao == "sociedade"){
      this.cookieService.set('perfilParticipante', "sociedade", { path: "/" });
      this.cookieService.set('documentoParticipante',"sociedade", { path: "/" });
      this.cookieService.set('uniqueIdParticipante', userData, { path: "/" });
      this.cookieService.set('tipoPessoa', "sociedade", { path: "/" });
    } else if (autorizacao == "corretora"){
      this.cookieService.set('perfilParticipante', "sociedade", { path: "/" });
      this.cookieService.set('documentoParticipante', "sociedade", { path: "/" });
      this.cookieService.set('uniqueIdParticipante', userData?.participante?.uniqueId, { path: "/" });
      this.cookieService.set('tipoPessoa', "sociedade", { path: "/" });
    } else{
      this.cookieService.set('perfilParticipante', autorizacao?.perfil?.nome, { path: "/" });
      this.cookieService.set('documentoParticipante', autorizacao?.participante?.documento, { path: "/" });
      this.cookieService.set('uniqueIdParticipante', autorizacao?.participante?.uniqueId, { path: "/" });
      if (autorizacao?.participante?.tipoPessoa?.name == "Física") {
        this.cookieService.set('tipoPessoa', "pf", { path: "/" });
      } else {
        this.cookieService.set('tipoPessoa', "pj", { path: "/" });
      }
    }
  }
}
