<nb-card>
    <nb-card-body >
        <div>
            <form [formGroup]="filtersForm">
                <div class="row mt-2">
                  

                    <div class="col px-2">
                        <label for="">CORRETORA</label>
             
                        <div class="form-group">
            
                            <input #autoInput fullWidth fieldSize="small" 
                                    nbInput
                                    type="text"
                                    (input)="onChange()"
                                    placeholder="Selecione uma corretora"
                                    [nbAutocomplete]="auto"  
                                    formControlName="corretora" 
                                    [focusInputOnValueChange]="false" />
            
                            <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)" [handleDisplayFn]="viewHandle">
            
                                    <nb-option *ngFor="let corretora of filteredOptions$ | async" [value]="corretora">
                                        {{ corretora.nome }}
                                    </nb-option>
            
                            </nb-autocomplete>
                        </div>
           
                        <!-- <nb-form-field>
                          <nb-icon nbPrefix icon="settings-outline" pack="eva"></nb-icon>
                          <nb-select placeholder="Corretoras" fullWidth="true" size="small">
                              <nb-option  [value]="null">Todos</nb-option>
                              <nb-option *ngFor="let s of unidadesCompradoras" [value]="s.uniqueId">{{s.nomeFantasia}}</nb-option>
                          </nb-select>
                      </nb-form-field> -->
                    </div>
                    
                    <div class="col px-2">
                        <label for="">Órgão Promotor</label>
                        <nb-form-field>
                          <nb-icon nbPrefix icon="settings-outline" pack="eva"></nb-icon>
                          <nb-select formControlName="orgaoPromotorUniqueId" placeholder="Órgão Promotor" fullWidth="true" size="small">
                              <nb-option  [value]="null">Todos</nb-option>
                              <nb-option *ngFor="let s of listaOP" [value]="s.uniqueId">{{s.nomeFantasia}}</nb-option>
                          </nb-select>
                      </nb-form-field>
                    </div>

        
                    <div class="col-2 px-2">
                        <label for="">Mês</label>
                        <nb-form-field>
                          <nb-icon nbPrefix icon="settings-outline" pack="eva"></nb-icon>
                          <nb-select formControlName="mes" placeholder="Selecione o mês" fullWidth="true" size="small">
                              <nb-option *ngFor="let mes of listaMes" [value]="mes.value">{{mes.mes}}</nb-option>
                          </nb-select>
                      </nb-form-field>
                    </div>
                    <div class="col-2 px-2">
                        <label for="">Ano</label>
                        <nb-form-field>
                          <nb-icon nbPrefix icon="settings-outline" pack="eva"></nb-icon>
                          <nb-select formControlName="ano" placeholder="Selecione o ano" fullWidth="true" size="small">
                              <nb-option  *ngFor="let ano of listaAno" [value]="ano">{{ano}}</nb-option>
                          </nb-select>
                      </nb-form-field>
                    </div>
                </div>
               
                <div class="row mt-2">
                    <div class="col-4 px-2">
                        <label for="">Relatório Sintético</label>
                        <nb-form-field>
                          <nb-icon nbPrefix icon="settings-outline" pack="eva"></nb-icon>
                          <nb-select formControlName="tipoRelatorio" placeholder="Selecione" fullWidth="true" size="small">
                              <nb-option  [value]="1">Ganhos</nb-option>
                              <nb-option  [value]="2">Demais transações</nb-option>
                          </nb-select>
                      </nb-form-field>
                    </div>
                </div>
                

                <div class="row mt-3 ">
                  
            
                    <div class="col-12 px-1 d-flex justify-content-start">
                        <button nbButton type="button"  class="mr-2" outline status="success" >Limpar filtros</button>
                        <button nbButton type="submit" class="" (click)="getRelatorios()" status="success" >Aplicar filtros</button>
                       
                    </div>
                </div>
            </form>
        </div>
      
                
        <div class="mt-2" *ngIf="listaDadosRelSin.length > 0">
            <nb-card class="text-center" status="info">
                <nb-card-header class="card-header" >
                    RELATÓRIO SINTÉTICO - {{tipoRelatorio}}
                </nb-card-header>

                <nb-card-body>
                    <table class="table table-bbm" >
                        <thead>
                            <tr>
                            <th>ÓRGÃO PROMOTOR</th>
                            <th>MÊS</th>
                            <th>ANO</th>
                            <th>Qtde.<br>Pregões Realizados<br></th>
                            <th>Total do período<br>Ressarcimento de Custos<br></th>
                            <th>Serviço contratado<br> Honorários devidos<br></th>
                            <th id="acaoContent" class="text-right pr-4">AÇÕES</th>
            
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dados of listaDadosRelSin; let i = index;" class="align-middle">
                                <td class="align-middle"> {{dados.nomeOrgaoPromotor}} </td>
                                <td class="align-middle"> {{dados.mes}}  </td>
                                <td class="align-middle"> {{dados.ano}}  </td>
                                <td class="align-middle"> {{dados.quantidadePregoesRealizados}}  </td>
                                <td class="align-middle"> {{dados.totalPeriodoRessarcimentoCustos}}  </td>
                                <td  class="align-middle"> {{dados.servicoContratadoHonorariosDevidos}}  </td>
                                
                                <td id="acaoContent">
                                    <div class="d-flex justify-content-center">
                                        <button  class="ml-2" nbButton status="success" (click)="confirmarAtivacao('teste')"  size="small"  nbTooltip="Relatório Analítico">A</button>
                                    </div>
                                </td>
                            </tr>
                          
            
                        </tbody>
                    </table>
                </nb-card-body>

            </nb-card>
        </div>
        <div *ngIf="listaDadosRelSin.length > 0">
            <button nbButton status="primary" (click)="visualizarDOC('relatorioSintetico');">Imprimir relatório Sintético</button>
        </div>
    </nb-card-body>
</nb-card>

<ng-template #dialogAnalitico let-data let-ref="dialogRef" >
    <nb-card class="text-center height-80" status="info" id="relatorioAnaliticoContent">
        <nb-card-header class="card-header">
            RELATÓRIO ANALÍTICO
        </nb-card-header>
        
        <nb-card-body>
            <div class="row mt-2">
                <table class="table table-bbm">
                    <thead>
                        <tr>
                            <th>ÓRGÃO PROMOTOR</th>
                            <th>Número do Edital</th>
                            <th>Cliente</th>
                            <th>Lote</th>
                            <th>Valor do Plano</th>
                            <th>Credenciamento/Renovação</th>
                            <th>Cadastro da Proposta</th>
                            <th>Cadastro da Primeira Proposta</th>
                            <th>Link do Edital </th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        <!-- <tr *ngFor="let edital of editaisProcessos; let i = index;" [ngClass]="{ 'disabled' : edital.ativo == 'inactive' }" class="align-middle"> -->
                        <tr class="align-middle">
                            <td class="align-middle"> BBMNET </td>
                            <td class="align-middle"> JANEIRO </td>
                            <td class="align-middle"> 2022 </td>
                            <td class="align-middle"> 3500 </td>
                            <td class="align-middle"> 1000 </td>
                            <td class="align-middle"> 2500 </td>
                            <td class="align-middle"> 3500 </td>
                            <td class="align-middle"> 1000 </td>
                            <td class="align-middle"> 2500 </td>
                        </tr>
                    
        
                    </tbody>
                </table>
            </div>
        </nb-card-body>
    </nb-card>
    <nb-card-footer class="text-center">
        <button nbButton status="danger" shape="semi-round" class="px-5 mr-2"  (click)="ref.close()">Cancelar</button>
    </nb-card-footer>
    <nb-card-footer class="text-center">
        <button nbButton status="primary" shape="semi-round" class="px-5 mr-2"  (click)="gerarArquivoPDF('relatorioAnaliticoContent')">Gerar PDF</button>
    </nb-card-footer>
    <nb-card-footer class="text-center">
        <button nbButton status="primary" shape="semi-round" class="px-5 mr-2"  (click)="ref.close()">Gerar XLS</button>
    </nb-card-footer>
</ng-template>

<ng-template #relatorioSintetico let-data let-ref="dialogRef">
    <nb-card  class="text-center height-80"  status="info" id="relatorioSinteticoContent">
        <nb-card-header class="card-header">
            RELATÓRIO SINTÉTICO - {{tipoRelatorio}}
        </nb-card-header>

        <nb-card-body>
            <table class="table table-bbm">
                <thead>
                    <tr>
                    <th>ÓRGÃO PROMOTOR</th>
                    <th>MÊS</th>
                    <th>ANO</th>
                    <th>Qtde.<br>Pregões Realizados<br></th>
                    <th>Total do período<br>Ressarcimento de Custos<br></th>
                    <th>Serviço contratado<br> Honorários devidos<br></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dados of listaDadosRelSin; let i = index;" class="align-middle">
                        <td class="align-middle"> {{dados.nomeOrgaoPromotor}} </td>
                        <td class="align-middle"> {{dados.mes}}  </td>
                        <td class="align-middle"> {{dados.ano}}  </td>
                        <td class="align-middle"> {{dados.quantidadePregoesRealizados}}  </td>
                        <td class="align-middle"> {{dados.totalPeriodoRessarcimentoCustos}}  </td>
                        <td  class="align-middle"> {{dados.servicoContratadoHonorariosDevidos}}  </td>
                    </tr>
                </tbody>
            </table>
        </nb-card-body>
    </nb-card>
    <div>
        <nb-card-footer class="text-center">
            <button nbButton status="danger" shape="semi-round" class="px-5 mr-2"  (click)="ref.close()">Cancelar</button>
        </nb-card-footer>
        <nb-card-footer class="text-center">
            <button nbButton status="primary" shape="semi-round" class="px-5 mr-2"  (click)="gerarArquivoPDF('relatorioSinteticoContent')">Gerar PDF</button>
        </nb-card-footer>
        <nb-card-footer class="text-center">
            <button nbButton status="primary" shape="semi-round" class="px-5 mr-2"  (click)="ref.close()">Gerar XLS</button>
        </nb-card-footer>
    </div>


   
</ng-template>