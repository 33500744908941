import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RelatorioComissaoService {

  public env = environment

  private headers = new HttpHeaders(
    {'Access-Control-Allow-Origin':this.env.frontRef}
  );

  constructor(private http: HttpClient) { }

  public getRelatorioComissaoSinteticoGanho(filters?: any): Observable<any> {
    return this.http.get(this.env.apiLicitacoes + '/api/RelatorioComissao/RelatorioSinteticoMeusGanho'+ this.parseFilters(filters));
  }

  public getCorretorasAtivas(){
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Corretora/ativas');
  }

  public getOrgaoPromotorPorCorretora(corretoraUniqueId:string){
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Corretora/' + corretoraUniqueId + '/orgaopromotor');
  }



  private parseFilters(filters?: any) {
      if (filters) {
          const filter_keys = Object.keys(filters);
          if (filter_keys.length) {
              const filter_array: string[] = [];
              filter_keys.map((filter: string) => {
                  if (filters[filter] !== null && filters[filter] !== undefined) {
                      filter_array.push(`${filter}=${filters[filter]}`);
                  }
              });
              const filter_string = `?${filter_array.join('&')}`;
              return filter_string;
          } else {
              return '';
          }
      } else {
          return '';
      }
  }



  

}
