import {createAction, createReducer,on, props} from "@ngrx/store";

enum ActionTypes {
  setListaParticipante = "setListaParticipante",
  getListaParticipante = "getListaParticipante",
}

export const SetListaParticipante  = createAction(
  ActionTypes.setListaParticipante,
  props<{payload: []}>()
)
export const GetListaParticipante  = createAction(
  ActionTypes.getListaParticipante
)

const INITIAL_STATE = {
  value: null
}

export const listaParticipanteReducer = createReducer(
  INITIAL_STATE,
  on(SetListaParticipante, (state, action) => ({
    ...state,
    value: action.payload
  })),
  on(GetListaParticipante, state => state),
);