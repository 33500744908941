import { LicitanteService } from './../shared/services/licitante.service';
import { UtilsService } from './../shared/services/utils.service';
import { OrgaoPromotorService } from './../shared/services/orgao-promotor.service';
import { LoadingService } from '../shared/services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogRef, NbToastrService, NbDialogService } from '@nebular/theme';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { InsertValidateCredencials } from '../shared/services/insert-validate-credencials';
import { SISTEMAS } from '../shared/enums/sistemas';
import { Autorizacao, UsuarioDoParticipante } from '../shared/models/participante';
import { PARTICIPANTE_STATUS, TIPO_PARTICIPANTE } from '../shared/enums/participante';
import { ParticipanteService } from '../shared/services/participantes.service';
import { ParticipanteForCookies } from '../shared/models/participante-dados-cookies.model';
import { TIPOS_PARTICIPANTES_USER } from '../shared/constants/tipoParticipantes';
import { CriptoLocalStorageService } from '../shared/services/cripto-localstorage/cripto-localstorage.service';
import { Store } from '@ngrx/store';
import { SetListaParticipante } from '../shared/store/listaParticipante.ngrx';

@Component({
  selector: 'app-controller',
  templateUrl: './controller.component.html',
  styleUrls: ['./controller.component.scss']
})
export class ControllerComponent implements OnInit {

  public userRoles: any;
  public userNamne: any;
  public env = environment;
  public tokenInfo: any;
  public resourceAccess: any;
  public idParticipante: string;
  public rota: string;
  public queryParams: any;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private toast: NbToastrService,
    private keycloak: KeycloakService,
    private cookieService: CriptoLocalStorageService,
    private loading: LoadingService,
    private orgaoPromotorService: OrgaoPromotorService,
    private licitanteService: LicitanteService,
    private utils: UtilsService,
    private participanteService: ParticipanteService,
    private insertValidateCredencial: InsertValidateCredencials,
    private store: Store<any>) { }

  ngOnInit(): void {
    /* this.loading.show('Validando suas informações'); */

    this.keycloak.isLoggedIn().then(boo => {
      if (boo) {
        this.tokenInfo = this.keycloak.getKeycloakInstance().idTokenParsed;
        // this.idParticipante = this.activatedRoute.snapshot.params.id?.split('|')[0];
        // this.rota = this.activatedRoute.snapshot.params.id?.split('|')[1];
        this.idParticipante = this.activatedRoute.snapshot.params.id?this.activatedRoute.snapshot.params.id:this.cookieService.get('uniqueIdParticipante');
        this.queryParams = this.activatedRoute.snapshot.queryParams;
        if (this.idParticipante) {
          this.getParticipanteData(this.tokenInfo.sub, this.idParticipante);
        } else {
          this.getParticipantes(this.tokenInfo.sub)
        }
      } else {
        this.cookieService.deleteAll("/");
        const config = {
          redirectUri: this.env.frontRef + "/controller"
        };
        this.keycloak.login(config);
      }
    });

  }

  getParticipanteData(idUsuario, idParticipante) {
    let  tipoParticipante = this.cookieService.get('tipoParticipante'); 
    if(idParticipante && tipoParticipante != TIPOS_PARTICIPANTES_USER.SOCIEDADE){
      this.participanteService.getDadosParticipante(idUsuario, idParticipante).subscribe((res) => {
        let result: ParticipanteForCookies = res;
        this.verificarInserirPerfis(result)
      }, error => {
        this.getParticipantes(this.tokenInfo.sub)
      })
    }else {
      let isSociedade = true;
      this.getParticipantes(this.tokenInfo.sub, isSociedade);
      this.router.navigateByUrl('/home/guia-sociedade');
    }
   
  }

  verificaParticipanteEnabled(participanteAutorizacao: Array<Autorizacao>, uniqueIdParticipante:string){
    let enabled = false;
    let autorizacao = participanteAutorizacao.find((authData: Autorizacao)=>{
      return authData?.participante?.uniqueId == uniqueIdParticipante;
    })
    if(autorizacao){
      if(autorizacao.enabled){
        enabled = true;
      }
    }
    return enabled;
  }

  verificarInserirPerfis(participante: ParticipanteForCookies) {
    let participanteEnabled:boolean = this.verificaParticipanteEnabled(participante.usuario.autorizacoes,participante.uniqueId);
    if(!participanteEnabled){
      this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
      this.setInfoUser(TIPO_PARTICIPANTE.sociedade);
      return;
    }

    if (participante.tipoParticipante.name == TIPO_PARTICIPANTE.orgaopromotor) {
      this.cookieService.set('tipoParticipante', "orgaopromotor", { path: "/" });
      this.setInfoUser(participante)
    } else if (participante.tipoParticipante.name == TIPO_PARTICIPANTE.unidadecompradora) {
      this.cookieService.set('tipoParticipante', "unidadecompradora", { path: "/" });
      this.setInfoUser(participante)
    } else if (participante.tipoParticipante.name == TIPO_PARTICIPANTE.cliente) {
      this.cookieService.set('tipoParticipante', "licitante", { path: "/" });
      this.setInfoUser(participante)
    } else if (participante.tipoParticipante.name == TIPO_PARTICIPANTE.bbm) {
      this.cookieService.set('tipoParticipante', "Bbm", { path: "/" });
      this.setInfoUser(participante)
    } else if (participante.tipoParticipante.name == TIPO_PARTICIPANTE.corretora) {
      this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
      this.setInfoUser(TIPO_PARTICIPANTE.corretora, participante)
    }else{
      this.cookieService.set('tipoParticipante', "sociedade", { path: "/" });
      this.setInfoUser(TIPO_PARTICIPANTE.sociedade)
    }


  }

  setInfoUser(participante, corretora?: any) {
    this.cookieService.set('IsUserCorretora', participante.usuario?.autorizacoes[0].corretora.toString(), { path: "/" });
    this.cookieService.set('uniqueIdUsuario', this.tokenInfo.sub, { path: "/" });
    if(participante == "Sociedade"){
      this.cookieService.set('perfilParticipante', "sociedade", { path: "/" });
      this.cookieService.set('documentoParticipante',"sociedade", { path: "/" });
      this.cookieService.set('uniqueIdParticipante', this.tokenInfo.sub, { path: "/" });
      this.cookieService.set('tipoPessoa', "sociedade", { path: "/" });
    } else if(participante == "corretora"){
      this.cookieService.set('perfilParticipante', "sociedade", { path: "/" });
      this.cookieService.set('documentoParticipante', participante.documento, { path: "/" });
      this.cookieService.set('uniqueIdParticipante', participante.uniqueId, { path: "/" });
      this.cookieService.set('tipoPessoa', "sociedade", { path: "/" });
    }else{
      this.cookieService.set('perfilParticipante', participante.usuario.perfil, { path: "/" });
      this.cookieService.set('documentoParticipante', participante.documento, { path: "/" });
      this.cookieService.set('uniqueIdParticipante', participante.uniqueId, { path: "/" });
      if (participante.tipoPessoa.name == "Física") {
        this.cookieService.set('tipoPessoa', "pf", { path: "/" });
      } else {
        this.cookieService.set('tipoPessoa', "pj", { path: "/" });
      }
    }
    this.getParticipantes(this.tokenInfo.sub)
  }

  getParticipantes(idUsuario: any, isSociedade?: boolean) {
    this.loading.show('Validando suas informações');
    this.orgaoPromotorService.getParticipantesUsuario(idUsuario).subscribe((resDataParticipante) => {
      if(!isSociedade){
        let res = resDataParticipante;
        res.autorizacoes = res?.autorizacoes?.filter((value)=>{
          let participantes: Array<any> = res.participantes;
          let filterParticipante = null  
          filterParticipante = participantes.find((participante)=>{
            return participante.participante.uniqueId == value?.participante?.uniqueId;
          })
          if(filterParticipante){
            if(filterParticipante.participante.participanteStatus.id == PARTICIPANTE_STATUS.ativo){
              if((filterParticipante.participante.tipoParticipante.name != TIPO_PARTICIPANTE.cliente || (filterParticipante.participante.tipoParticipante.name == TIPO_PARTICIPANTE.cliente && filterParticipante.participante.assinaturaStatus.id == 1)) && value?.enabled == true){
                return true;
              }
              return false;
            }
            return false;
          }
          return false;
        })
        let autorizacao: any = res.autorizacoes;
        this.resourceAccess = this.keycloak.getKeycloakInstance().resourceAccess;
        if (this.tokenInfo.email_verified) {
          this.validarAcesso(autorizacao,res);
        } else {
          this.loading.hide();
          this.router.navigateByUrl('/acesso-negado-email');
        }
        if (this.activatedRoute.snapshot.params['ref'] == 'login') {
          const config = {
            redirectUri: this.env.frontRef + "/controller"
          };
          this.keycloak.login(config);
        }
        if (this.activatedRoute.snapshot.params['ref'] == 'logout') {
          const redirectUri = this.env.frontRef + '/auth/login';
          this.keycloak.logout(redirectUri);
        }
      }
      this.loading.hide();
      this.store.dispatch(SetListaParticipante({payload: resDataParticipante}))
    })
  }



  public validarAcesso(autorizacao: any,usuarioInfo:any) {
    if(autorizacao.length > 1){
      if (this.idParticipante != undefined && this.idParticipante != null) {
        autorizacao = autorizacao.filter((p: any) => {
          return p.participante.uniqueId == this.idParticipante;
        })
      }
      autorizacao = autorizacao.filter((p: any) => {
        return p.sistema.uniqueId == SISTEMAS.BBMNETLicitacoes && p.enabled;
      })
      
      if(autorizacao.length<1){
        autorizacao = usuarioInfo;
      }
    }else if(autorizacao.length == 0){
      autorizacao = usuarioInfo;
    }
    

    const userRoles: string[] = this.insertValidateCredencial.getUserRoles(autorizacao);


    if(this.queryParams.route){
      this.loading.hide();
      this.redirectForRoute(this.queryParams.route)
      return
    }

    if (userRoles.includes('orgaopromotor') || userRoles.includes('unidadecompradora')) {
      this.loading.hide();
      this.router.navigateByUrl('/home/guia-pregoeiro');
      return
    }
    if (userRoles.includes('sociedade') || userRoles.includes("corretora")) {
      this.loading.hide();
      this.router.navigateByUrl('/home/guia-sociedade');
      return
    }

    if (userRoles.includes('Bbm')) {
      this.loading.hide();
      this.router.navigateByUrl('/visaoeditais');
      return
    }

    if (userRoles.includes('licitante')) {
      this.loading.hide();
      this.router.navigateByUrl('/home/guia');
      return
    }
  }

  verificaPermissaoLicitacao(participante: UsuarioDoParticipante, participanteId: string): boolean {
    let permite = false;
    participante.autorizacoes.filter((auth) => {
      if (auth.sistema.uniqueId == SISTEMAS.BBMNETLicitacoes && auth.participante.uniqueId == participanteId) {
        permite = true
      }
    })
    return permite
  }

  logout() {
    this.keycloak.logout();
  }

  redirectForRoute(route: string){
    if(route == "orgaopromotor_editais"){
      this.router.navigateByUrl('/orgaopromotor/editais');
    } else if(route == "visao_editais") {
      this.router.navigateByUrl('/visaoeditais/editais');
    } else if(route == "licitante_documentos") {
      this.router.navigateByUrl('/licitante/documentos');
    } else if(route == "esclarecimentos") {
      this.router.navigateByUrl('/esclarecimentos');
    } else if(route == "impugnacoes") {
      this.router.navigateByUrl('/impugnacoes');
    } else if(route == "LEIS") {
      this.router.navigateByUrl('/leis/lista');
    } else if(route == "PLANOS") {
      this.router.navigateByUrl('/planos/change-plano-avulso');
    } else if(route == "ETP") {
      this.router.navigateByUrl('/estudo-tecnico-preliminar');
    } else if(route == "PESQUISA_PRECO") {
      this.router.navigateByUrl('/pesquisa-preco');
    }else if(route == "proposta_planilha") {
      let uniqueIdEdital = this.queryParams.uniqueIdEdital;
      if(uniqueIdEdital){
        this.router.navigateByUrl(`/cadastro/proposta/planilha/${uniqueIdEdital}`);
      }else{
        this.router.navigateByUrl('/visaoeditais/editais');
      }
    } else {
      this.router.navigateByUrl('/controller');
    }
  }

}
