<nb-layout>
  <nb-layout-column class="px-0 pb-0">
    <!-- <div class="container-bbm header-credenciamento">
      <div class="row">
        <div class="col-6 col-md-2">
          <a [routerLink]="['/']">
            <img src="./assets/images/bbmnet-login.png" alt="" class="img-fluid mb-4 pr-2">
          </a>
        </div>

        <div class="col-6 col-md text-right">
          <h6 class="mb-0"><span class="font-weight-lighter">Olá, </span> {{this.idUsuario.nome}}</h6>
          <h5  class="d-inline-block pb-2 btn-logout mb-3">Sair <span> <button nbButton status="danger" size="small"><nb-icon icon="log-out-outline" (click)="logout()"></nb-icon>  </button> </span></h5>
        </div>
      </div>
      <div>
        <h4 class="d-flex justify-content-center">PARTICIPANTES VINCULADOS</h4>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-8 col-md-4 col-sm-4 col-lg-3 cards d-flex align-items-stretch"
          *ngFor="let usuario of usuarios; let i = index">

          <div>

            <div class="info-participante">
              <p class="text-left"
                [ngClass]="(usuario.participante.participanteStatus.name == 'Ativo')?'ativo':'rascunho'">
                {{usuario.participante.participanteStatus.name}}</p>
              <h6 class="text-left">{{ usuario.participante.nome }}</h6>
              <p class="text-left">{{usuario.participante.documento }}</p>
              <p class="text-left"><b>{{usuario?.participante?.tipoParticipante?.name}}</b></p>
            </div>

            <div
              nbTooltip="{{(usuario.participanteUsuarioStatus.name=='Inativo')? 'Este participante está inativo neste usuário.':'Ir para Sala de Disputa'}}">
              <button nbButton status="primary" [disabled]="usuario.participanteUsuarioStatus.name == 'Inativo'"
                class="botaousuario w100" (click)="goToPage(usuario)">Entrar com este Participante</button>

            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div id="login" class="container-participantes">
     
      <form [formGroup]="form">
        <div>
          <img src="assets/images/logo-bolsa.png" class="login_logo" alt="" />
        </div>
        <nb-card class="background-card">
          <nb-card-body>
            <div>
              <h4 class="d-flex justify-content-center">PARTICIPANTES VINCULADOS</h4>
            </div>
            <div class="card-list-participante row">
              <div class="col-12">
                <nb-select placeholder="Participantes" fullWidth="true" size="small" formControlName="usuario" class="select_participante">
                  <nb-option *ngFor="let usuario of usuarios; let i = index"
                    [value]="usuario">{{usuario.participante.nome}}</nb-option>
                </nb-select>
              </div>
              <div class="col-12">
                <button nbButton status="primary" [disabled]="!this.f.usuario.valid || this.f.usuario?.value?.participanteUsuarioStatus?.name == 'Inativo'"  fullWidth  (click)="goToPage()">Entrar com este
                  Participante</button>
                 
              </div>
              <br>
              <div class="col-12">
                <button nbButton status="danger"  fullWidth (click)="logout()">Sair</button>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </form>
    </div>
  </nb-layout-column>
</nb-layout>