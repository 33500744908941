import { Globals } from '../globals';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard  {

  private logged = false;
  static RolesGuard: any;

  constructor(protected router: Router, private globals: Globals, protected readonly keycloak: KeycloakService){ 
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    } else {
      return true;
    }
  }

  checkLogin(): boolean {
    if (!this.globals.auth) {
      this.router.navigate(['/auth/login']);
    }

    return this.globals.auth ? true : false;
  }

}
