
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public naturezaOrgaoPromotor: any;
  public env = environment
  public nomeParticipante: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) { }

  public atualizarPermissoesUsuario(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/usuario/' + body.id + '/perfil/' + body.perfilId +'/adicionar', {});
  }

  public deletePermissaoUsuario(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/usuario/' + body.id + '/perfil/' + body.perfilId +'/remover', {});
  }

  public atualizarPermissoesPF(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/usuario/' + body.id + '/perfil/' + body.perfis[0].perfilId +'/adicionar', {});
  }
  
  public getParticipantesUsuario(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/Usuario/'+idParticipante+'/participantes');
  }


  public getUsuariosLicitante(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/'+idParticipante+'/usuario');
  }

  public deleteUsuarioLicitante(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/usuario/' + idUsuario);
  }

  public adicionarUsuariosLicitante(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/usuario', body);
  }

  public atualizarUsuariosLicitante(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/usuario', body);
  }

  public atualizarPermissoesLicitante(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/usuario/' + body.id + '/perfil/' + body.perfilId +'/adicionar', {});
  }

  public deletePermissaoLicitante(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/usuario/' + body.id + '/perfil/' + body.perfilId +'/remover', {});
  }

  public getUsuariosBBM(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/admin/bbm/usuario/' + idParticipante);
  }

  public deleteUsuarioBBM(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/admin/bbm/usuario/' + idUsuario);
  }

  public mudarStatusUsuarioBBM(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/bbm/usuario/status', body);
  }

  public adicionarUsuariosBBM(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/admin/bbm/usuario', body);
  }

  public atualizarUsuariosBBM(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/bbm/usuario', body);
  }

  public atualizarPermissoesBBM(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/usuario/' + body.id + '/perfil/' + body.perfilId +'/adicionar', {});
  }

  public deletePermissaoBBM(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/admin/usuario/' + body.id + '/perfil/' + body.perfilId +'/remover', {});
  }

  public getUsuarios(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/'+idParticipante+'/usuario');
  }

  public getUsuariosOrgaoPromotor(idOrgao: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/'+ idOrgao +'/usuario');
  }


  public deleteUsuario(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/Usuario/' + idUsuario);
  }

  public mudarStatusUsuario(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/Usuario/status', body);
  }

  public mudarStatusUsuarioOrgaoPromotor(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/usuario/status', body);
  }

  public mudarStatusUsuarioLicitante(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/licitante/usuario/status', body);
  }

  public adicionarUsuarios(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/Usuario', body);
  }

  public adicionarUsuarioOrgaoPromotor(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/usuario', body);
  }

  public atualizarUsuarios(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/Usuario', body);
  }

  public adicionarUsuariosOrgaoPromotor(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/usuario', body);
  }

  public atualizarUsuariosOrgaoPromotor(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/usuario', body);
  }

  public deleteUsuarioOrgaoPromotor(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/cadastro/orgaopromotor/usuario/' + idUsuario);
  }

  public getUsuariosUnidadeCompradora(idParticipante: string): Observable<any> {
    return this.http.get(this.env.apiCadastroParticipantes + '/api/cadastro/unidadeCompradora/'+ idParticipante +'/usuario');
  }

  public adicionarUsuarioUnidadeCompradora(body: any): Observable<any> {
    return this.http.post(this.env.apiCadastroParticipantes + '/api/cadastro/unidadecompradora/usuario', body);
  }

  public atualizarUsuarioUnidadeCompradora(body: any): Observable<any> {
    return this.http.put(this.env.apiCadastroParticipantes + '/api/cadastro/unidadecompradora/usuario', body);
  }

  public deleteUsuarioUnidadeCompradora(idUsuario: string): Observable<any> {
    return this.http.delete(this.env.apiCadastroParticipantes + '/api/cadastro/unidadecompradora/usuario/' + idUsuario);
  }


}
