import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NbAccordionModule, NbAlertModule, NbAutocompleteModule, NbButtonModule, NbCalendarModule, NbCardModule, NbDatepickerModule, NbDialogModule, NbFormFieldModule, NbIconModule, NbInputModule, NbMenuModule, NbSelectModule, NbSpinnerModule, NbTabsetModule, NbTooltipModule } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { GuiaComponent } from './guia/guia.component';
import { GuiaComponentPregoeiro } from './guia-pregoeiro/guia-pregoeiro.component';
import { GuiaSociedadeComponent } from './guia_sociedade/guia-sociedade.component';



@NgModule({
  declarations: [HomeComponent, GuiaComponent,GuiaComponentPregoeiro, GuiaSociedadeComponent],
  imports: [
    CommonModule,
    ThemeModule,
    NbMenuModule,
    NbCardModule,
    NbAlertModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NbFormFieldModule,
    NbDatepickerModule,
    NbDialogModule,
    NbAccordionModule,
    NbTabsetModule,
    NbSelectModule,
    NbTooltipModule,
    SharedModule,
    ReactiveFormsModule,
    NbCalendarModule,
    NbAutocompleteModule,
    HomeRoutingModule
  ]
})
export class HomeModule { }
