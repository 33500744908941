import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filterPerfis'
})
export class FilterPerfisPipe implements PipeTransform {
    transform(items: any[], clientId: number): any {
        if (!items || !clientId) {
            return '';
        }
        const item:any = items.filter(item => item.clientId === clientId);
        return item[0].perfis;
    }
}