export const environment = {
  retirarHorasData: false,
  production: false,
  frontRefSalaDisputa:"https://pitang-bbmnet-sala-disputa.firebaseapp.com",
  buscaCepApi: "https://viacep.com.br/ws/",
  apiCadastroParticipantes: "https://cadastro-participantes-backend-rvdavvn5uq-uc.a.run.app",
  frontRef: 'https://pitang-bbmnet-licitacoes-dev.firebaseapp.com',
  kc: "https://pitang-bbm-cadastro-dev.uc.r.appspot.com/auth/",
  apiLicitacoes: "https://cadastro-editais-backend-nhzmasfena-uc.a.run.app",
  salaDisputaBackend: "https://us-central1-pitang-bbmnet-licitacoes-dev.cloudfunctions.net/api",
  salaDisputaFast: "https://us-central1-pitang-bbmnet-licitacoes-dev.cloudfunctions.net/fastRoutes",
  salaDisputaUteis: "https://us-central1-pitang-bbmnet-licitacoes-dev.cloudfunctions.net/uteis",
  firebaseConfig: {
    apiKey: "AIzaSyAeE2cfCk3eMz7P7D2NJbFh3QGicFBvBoY",
    authDomain: "pitang-bbm-dev-fb.firebaseapp.com",
    databaseURL: "https://pitang-bbm-dev-fb.firebaseio.com",
    projectId: "pitang-bbm-dev-fb",
    storageBucket: "pitang-bbm-dev-fb.appspot.com",
    messagingSenderId: "372508572451",
    appId: "1:372508572451:web:d5f0e39e6d32f6c7cdea96"
  },
  criptoLocalKey: "tes4dP@mfhr57QeQBAS6t^"
};
