<nb-card style="width: 800px; max-width: 800px; max-height: 90vh;">
  <nb-card-header>
    <h6>Verificação de Lotes Importados</h6>
  </nb-card-header>
  <nb-card-body>
    <div *ngFor="let lote of lotes; let i = index">
      <nb-card>
        <nb-card-body>
          <div class="row lista_lotes">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h6>Numero do Lote - {{lote?.lote}}</h6>
                </div>
                <div class="col-6">
                  <p><b>FazerPropostaComo:</b> {{lote?.lanceDTO?.FazerPropostaComo}}</p>
                </div>
              </div>
              <div *ngFor="let item of lote.lanceDTO?.LancesPorItem; let i = index">
                <div class="row mb-1 item">
                  <div class="col-6">
                    <p *ngIf="item?.Produto"><b>Produto:</b> {{item?.Produto}}</p>
                  </div>
                  <div class="col-6">
                    <p *ngIf="item?.Quantidade"><b>Quantidade:</b> {{valorDecimalQuantidade(item?.Quantidade)}}</p>
                  </div>
                  <div class="col-6">
                    <p *ngIf="item?.UnidadeMedida?.Marca"><b>Marca:</b> {{item?.UnidadeMedida?.Marca}}</p>
                   </div>
                  <div class="col-6">
                    <p *ngIf="item?.UnidadeMedida?.Descricao"><b>UnidadeMedida:</b> {{item?.UnidadeMedida?.Descricao}}</p>
                  </div>
                  <div class="col-6">
                    <p *ngIf="item?.UnidadeMedida?.Valor"><b>Valor:</b> {{item?.UnidadeMedida?.Valor}}</p>
                  </div>
                 <div class="col-6">
                  <p *ngIf="item?.UnidadeMedida?.PrecoReferencia"><b>PrecoReferencia:</b> {{item?.UnidadeMedida?.PrecoReferencia}}</p>
                 </div>
              
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      
    </div>
    
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex justify-content-end">
      <button nbButton size="medium" status="primary" (click)="close()">Prosseguir</button>
    </div>
   
  </nb-card-footer>
</nb-card>